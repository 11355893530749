.Portafolio {
  background: #f5f5f5;
  padding: 15rem 0 12rem 0;
  overflow: visible;
}
.Portafolio__item {
  margin-bottom: 3rem;
  font-size: 2.4rem;
  color: #4a4a4a;
  margin-right: 4rem;
}
.Portafolio__item strong {
  color: #055aa5;
}
.Portafolio__triangles {
  position: relative;
}
.Portafolio__triangles .big-red-triangle {
  position: absolute;
  width: 140rem;
  height: 100rem;
  background: url(../../../../images/triangulo-rojo.svg) no-repeat left center/contain;
  top: 50%;
  margin-top: -50rem;
  left: 9rem;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 8rem;
  padding-right: 96rem;
  padding-top: 10rem;
  transform: rotate(0);
}
.Portafolio__triangles .big-red-triangle h1 {
  font-size: 3.8rem;
}
.Portafolio__triangles .big-blue-triangle {
  position: absolute;
  width: 100rem;
  height: 90rem;
  background: url(../../../../images/triangulo-azul.svg) no-repeat center center/contain;
  top: -30rem;
  right: -60rem;
  transform: rotate(0);
}

@media (max-width: 1180px) {
  .Portafolio h2 {
    font-size: 2.2rem;
  }
}
@media (max-width: 576px) {
  .Portafolio {
    padding: 26rem 0 0rem 0;
    margin-top: 20rem;
  }
  .Portafolio .row {
    justify-content: center;
  }
  .Portafolio h2 {
    font-size: 2.2rem;
  }
  .Portafolio__item {
    font-size: 1.6rem;
    margin-left: 6rem;
  }
  .Portafolio__triangles .big-red-triangle {
    width: 70rem;
    height: 50rem;
    top: -60rem;
    right: -35rem;
    padding-left: 6rem;
    padding-right: 36rem;
    padding-top: 6rem;
    z-index: 1;
  }
  .Portafolio__triangles .big-red-triangle h1 {
    font-size: 3rem;
  }
  .Portafolio__triangles .big-blue-triangle {
    width: 50rem;
    height: 25rem;
    top: -96rem;
    right: 8rem;
    z-index: 0;
  }
}