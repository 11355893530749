.FindNews {
  display: flex;
  align-items: center;
  margin: 6rem auto 20rem auto;
}
.FindNews .card {
  margin-bottom: 6rem;
  padding: 0;
}
.FindNews form {
  padding: 0;
}
.FindNews__row {
  justify-content: center;
}
.FindNews__form-input {
  border-radius: 0;
  border: solid 0.1rem #4a4a4a;
  font-size: 2.8rem;
  padding: 2rem;
  width: 100%;
  height: 7.6rem;
}
.FindNews__form {
  display: flex;
  align-items: center;
  height: 7.6rem;
  position: relative;
}
.FindNews__form .form-group {
  margin: 0 !important;
}
.FindNews__form .leti-btn {
  background: url(../../../../images/search.svg) no-repeat center/4rem;
  height: 7.6rem;
  transition: all 0.1s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
}
.FindNews__form .leti-btn:hover {
  border-width: 0.3rem;
}
.FindNews__noResults {
  margin-bottom: 6rem;
}
.FindNews__noResults p:first-child {
  margin-bottom: 3rem;
}
.FindNews__checkboxes {
  border: solid 0.1rem #4a4a4a;
  border-top: 0;
  padding: 0;
}
.FindNews__checkboxes .col {
  border-right: solid 0.1rem #4a4a4a;
  display: flex;
  align-items: center;
}
.FindNews__checkboxes .col:last-child {
  border-right: 0;
}
.FindNews__tag {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 1rem;
  color: #cd202f;
  font-family: "TripletaBold";
}
.FindNews .CheckBoxWithLabel .CheckBoxWithLabel {
  padding: 0;
}
.FindNews .CheckBoxWithLabel .CheckBoxWithLabel label {
  font-size: 1.4rem;
}
.FindNews .CheckBoxWithLabel .CheckBoxWithLabel .form-check {
  padding: 2rem 0;
  padding-left: 4rem;
}

@media (max-width: 576px) {
  .FindNews {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 6rem;
  }
  .FindNews__form {
    height: unset;
  }
  .FindNews__form-input {
    font-size: 1.6rem;
  }
  .FindNews .leti-btn {
    display: none;
  }
  .FindNews__noResults {
    padding: 0 2rem;
    font-size: 1.6rem;
  }
  .FindNews .card-body {
    padding: 2rem;
  }
  .FindNews .card-footer {
    padding: 0 2rem;
  }
  .FindNews .card-footer .leti-btn {
    display: block;
  }
  .FindNews__checkboxes {
    border: solid 0.1rem #4a4a4a;
    border-top: 0;
  }
  .FindNews__checkboxes .col {
    padding: 1rem 2rem !important;
    flex: none;
    border-right: 0;
  }
  .FindNews__checkboxes .col:last-child {
    border-right: 0;
  }
}
.TagWrapper {
  display: flex;
  gap: 10px;
}