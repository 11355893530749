.BiocontrolledInfo-Logo {
  width: 100%;
  height: 13rem;
  background: url("https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fbiocontrolled-grupoleti.svg?alt=media&token=bb4b5310-b033-41fc-a465-f0f1208e89bf") no-repeat center/contain;
  margin: 14rem auto 7rem auto;
}
.BiocontrolledInfo-WhoWeAre h1 {
  font-size: 6.4rem;
  text-align: center;
  color: #1a1a1a;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 16rem 5rem 16rem;
}
.BiocontrolledInfo-WhoWeAre p {
  font-size: 2.8rem;
  color: #737373;
  line-height: 1.5;
  margin-bottom: 18rem;
}
.BiocontrolledInfo-WhoWeAre p b,
.BiocontrolledInfo-WhoWeAre p strong {
  color: #1a1a1a;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Logo {
    height: 10rem;
    margin: 5rem auto;
    width: 80%;
  }
  .BiocontrolledInfo-WhoWeAre {
    padding: 0 4rem;
  }
  .BiocontrolledInfo-WhoWeAre h1 {
    font-size: 3.5rem;
    margin: 0 2rem 2rem 2rem;
  }
  .BiocontrolledInfo-WhoWeAre p {
    font-size: 1.8rem;
    margin-bottom: 3rem;
    text-align: center;
  }
}