.BiocontrolledInfo-TechCards .row {
  flex-wrap: nowrap;
  overflow: scroll;
  padding-bottom: 4rem;
}
.BiocontrolledInfo-TechCards .card {
  background-color: #f2f2f2;
  border-radius: 3rem;
  padding: 3.6rem 4.5rem;
  padding-bottom: 9rem;
  position: relative;
  transition: all 0.2s ease-in-out;
  height: 40rem;
  overflow: hidden;
}
.BiocontrolledInfo-TechCards .card:first-child {
  margin-left: 4rem;
}
.BiocontrolledInfo-TechCards .card .btn {
  transition: all 0.2s ease-in-out;
  width: 6.5rem;
  height: 6.5rem;
  padding: 0;
  position: absolute;
  right: 3rem;
  bottom: 3rem;
}
.BiocontrolledInfo-TechCards .card .arrow {
  background: url(../../../../../images/bioletisan-card-arrow.svg) no-repeat center/contain;
  width: 6.5rem;
  height: 6.5rem;
  display: block;
}
.BiocontrolledInfo-TechCards .card-title {
  font-size: 3.4rem;
  font-weight: 600;
  color: #1a1a1a;
  text-transform: uppercase;
  margin-bottom: 3rem;
}
.BiocontrolledInfo-TechCards .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.BiocontrolledInfo-TechCards .card-text {
  font-size: 2.8rem;
  color: #1a1a1a;
  line-height: 1.5;
  font-weight: 100 !important;
  transition: all 0.2s ease-in-out;
  display: block;
}
.BiocontrolledInfo-TechCards .card-text span {
  font-weight: 100 !important;
}
.BiocontrolledInfo-TechCards .card-text--all {
  font-size: 2.8rem;
  color: #1a1a1a;
  line-height: 1.5;
  font-weight: 100 !important;
  transition: all 0.2s ease-in-out;
  display: none;
}
.BiocontrolledInfo-TechCards .card-text--all span {
  font-weight: 100 !important;
}
.BiocontrolledInfo-TechCards .card:hover {
  background-color: rgba(29, 91, 168, 0.15);
  cursor: pointer;
  height: fit-content;
}
.BiocontrolledInfo-TechCards .card:hover .btn {
  transform: rotate(-90deg);
}
.BiocontrolledInfo-TechCards .card:hover .card-text {
  display: none;
}
.BiocontrolledInfo-TechCards .card:hover .card-text--all {
  display: block;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-TechCards .row {
    flex-wrap: nowrap;
    overflow: scroll;
    padding-bottom: 0;
  }
  .BiocontrolledInfo-TechCards .card {
    width: 100%;
    margin: 0 auto 1rem auto;
    padding: 1rem;
    min-height: 0;
  }
  .BiocontrolledInfo-TechCards .card:first-child {
    margin-left: 0;
  }
  .BiocontrolledInfo-TechCards .card .btn {
    float: none;
    width: 4rem;
    height: 4rem;
  }
  .BiocontrolledInfo-TechCards .card .arrow {
    width: 4rem;
    height: 4rem;
  }
  .BiocontrolledInfo-TechCards .card-title {
    font-size: 3.2rem;
  }
  .BiocontrolledInfo-TechCards .card-text {
    font-size: 1.6rem;
  }
}