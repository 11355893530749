.Director {
  background: #f5f5f5;
  margin-top: 45rem;
  position: relative;
}
.Director__image {
  height: 61rem;
  padding: 0;
  position: absolute;
  left: 0;
  top: -35rem;
}
.Director__info {
  font-size: 2.4rem;
  padding: 8rem 0 10rem 0;
  position: relative;
}
.Director__info h1 {
  position: absolute;
  left: 3rem;
  font-size: 5rem;
  top: -20rem;
}
.Director__info p, .Director__info small {
  padding-left: 3rem;
}
.Director__info p {
  margin-bottom: 0rem;
  position: relative;
}
.Director__info p:before, .Director__info p:after {
  content: '"';
  font-size: 3rem;
}
.Director__info small {
  margin-bottom: 3rem;
  display: block;
  font-size: 1.6rem;
  font-style: italic;
  margin-top: 3rem;
}
.Director__info small:before {
  content: "- ";
}
.Director__info .leti-btn {
  margin-left: 3rem;
}

@media (max-width: 576px) {
  .Director {
    margin-top: 0rem;
  }
  .Director__image {
    height: 35rem;
    position: static;
  }
  .Director__info {
    font-size: 1.6rem;
    padding: 4rem 2rem;
  }
  .Director__info h1 {
    position: static;
    font-size: 3.6rem;
  }
  .Director__info p {
    width: unset;
    padding: 0;
  }
  .Director__info .leti-btn {
    margin-left: 0;
  }
}