.Video__genven {
  height: 70rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  margin: 20rem 0;
}

@media (max-width: 576px) {
  .Video__genven {
    height: 30rem;
    margin: 20rem 0 0 0;
  }
}