.Banner__Leti {
  position: relative;
}
.Banner__Leti-logo {
  margin-top: 6rem;
  display: block;
}
.Banner__Leti__title {
  height: 70rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
}
.Banner__Leti__title h3 {
  font-size: 3rem;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  margin-bottom: 8rem;
}
.Banner__Leti__bg {
  position: absolute;
  width: 54rem;
  height: 54rem;
  right: -6rem;
  top: -10rem;
  transform: rotate(0deg);
}
.Banner__Leti__blue {
  position: absolute;
  width: 44rem;
  height: 44rem;
  background: url(../../../../images/leti-blue-banner.png) no-repeat center/contain;
  right: -6rem;
  top: 12rem;
  transform: rotate(0deg);
}

@media (max-width: 576px) {
  .Banner__Leti-logo {
    width: 13rem;
    margin: 5rem 0 3rem 0;
  }
  .Banner__Leti__title {
    height: 85rem;
    justify-content: flex-start;
  }
  .Banner__Leti__title h3 {
    font-size: 2rem;
    margin-bottom: 8rem;
    line-height: 2.8rem;
  }
  .Banner__Leti__bg {
    width: 38rem;
    height: 38rem;
    right: -6rem;
    top: 35rem;
  }
  .Banner__Leti__blue {
    width: 30rem;
    height: 30rem;
    right: -6rem;
    top: 51rem;
  }
}