.LandingGoals {
  margin-top: 8.2rem;
  height: 70rem;
}
.LandingGoals--image {
  height: 70rem;
}
.LandingGoals--title {
  font-family: "Work Sans", sans-serif;
  font-size: 41px;
  font-weight: 700;
  color: #808080;
  margin-bottom: 3.8rem;
}
.LandingGoals--title strong,
.LandingGoals--title b {
  color: #1095f2;
  font-weight: 700;
}
.LandingGoals--list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 5.5rem;
  flex-direction: column;
  position: relative;
}
.LandingGoals--list:before {
  content: "";
  height: 95%;
  width: 3px;
  background-color: #d9d9d9;
  display: block;
  top: 10px;
  position: absolute;
  left: 28.5px;
  z-index: -1;
}
.LandingGoals--list li {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #808080;
  display: flex;
  align-items: center;
  gap: 4rem;
}
.LandingGoals--list li div {
  padding-right: 6rem;
}
.LandingGoals--list li .icon {
  width: 6rem;
  height: 6rem;
  display: block;
  flex-shrink: 0;
  border-radius: 500px;
}

@media (max-width: 48rem) {
  .LandingGoals {
    margin-top: 6rem;
  }
  .LandingGoals--image {
    display: none;
  }
  .LandingGoals--content {
    padding: 0 1.6rem;
  }
  .LandingGoals--title {
    text-align: center;
    font-size: 3.2rem;
    margin-bottom: 2.8rem;
  }
  .LandingGoals--list li {
    font-size: 1.6rem;
    line-height: normal;
    gap: 2rem;
  }
  .LandingGoals--list li div {
    padding-right: 0;
  }
}