.InfoBannerID {
  background: url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fid-bg.jpg?alt=media&token=19a66cee-e781-4463-8ac3-999112a636e7) no-repeat center/cover;
  position: relative;
  overflow: hidden;
  height: 70rem;
  padding: 0;
}
.InfoBannerID-data {
  padding: 4rem 0;
  background: rgba(255, 255, 255, 0.86);
  position: absolute;
  bottom: 0;
  left: 0;
}
.InfoBannerID-colaboradores {
  color: #000;
  font-size: 10rem;
  font-weight: 600;
  border-bottom: solid 1px #000;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  position: relative;
  padding-left: 13rem;
  padding-top: 2rem;
}
.InfoBannerID-colaboradores-texto {
  color: #000;
  font-weight: normal;
  display: block;
  font-size: 1.4rem;
  margin-top: 4rem;
}
.InfoBannerID-colaboradores:before {
  content: "";
  width: 10rem;
  height: 10rem;
  display: inline-block;
  background: url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fhands.svg?alt=media&token=80c266f5-66ea-438c-a743-109499665cf5) no-repeat center/contain;
  position: absolute;
  left: 0;
  top: 0;
}

.InfoBanner-aside {
  padding-top: 4rem;
}
.InfoBanner-aside .custom-margin {
  margin-top: 5rem;
}
.InfoBanner-aside .custom-margin,
.InfoBanner-aside .text {
  font-size: 2rem;
  color: #000;
}
.InfoBanner-aside .InfoBannerID-colaboradores {
  border-bottom: 0;
}
.InfoBanner-aside .InfoBannerID-colaboradores-icons {
  padding: 0 0 0 8rem;
  font-size: 4rem;
}
.InfoBanner-aside .InfoBannerID-colaboradores-icons:before {
  width: 7rem;
  height: 7rem;
  top: 0;
}
.InfoBanner-aside .InfoBannerID-colaboradores-texto {
  margin-top: 0;
  padding-left: 8rem;
}

@media (max-width: 1180px) {
  .InfoBanner-aside .InfoBannerID-colaboradores {
    font-size: 7rem;
  }
  .InfoBanner-aside .InfoBannerID-colaboradores-icon {
    font-size: 3rem;
  }
  .InfoBanner-aside .InfoBannerID-colaboradores-texto {
    font-size: 2.4rem;
  }
}
@media (max-width: 576px) {
  .InfoBannerID {
    overflow: visible;
    height: 24rem;
    margin-bottom: 56rem;
  }
  .InfoBannerID-data {
    bottom: -58rem;
    padding: 0 2rem;
  }
  .InfoBanner-aside .InfoBannerID-colaboradores-icons {
    font-size: 2rem;
    padding: 0 0 0 4rem;
  }
  .InfoBanner-aside .InfoBannerID-colaboradores-icons:before {
    width: 3rem;
    height: 3rem;
  }
  .InfoBanner-aside .InfoBannerID-colaboradores-texto {
    padding-left: 4rem;
  }
  .InfoBanner-aside__title {
    height: 70rem;
    align-items: flex-start;
  }
  .InfoBanner-aside__title h1 {
    margin-top: 3rem;
  }
}