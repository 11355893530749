.ProductNotFound__pic {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 472px;
  width: 80%;
}
.ProductNotFound__pic--logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 0.1rem #e9eaee;
  border-radius: 2rem;
  padding: 2rem 0;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2);
  width: 36%;
}
.ProductNotFound__pic--logo img {
  max-width: 18rem;
  transition: all 0.2s ease-in-out;
}
.ProductNotFound__pic--logo.locatel {
  background-color: #01962d;
}
.ProductNotFound__pic--logo.farmatodo {
  background-color: #202d57;
}
.ProductNotFound__pic--logo:hover img {
  transform: scale(1.1);
}
.ProductNotFound__back {
  color: #055aa5;
  font-size: 2rem;
  display: inherit;
  margin: 8rem auto;
  transition: all 0.3s ease-in-out;
  margin-left: 0;
}
.ProductNotFound__back a {
  color: #055aa5;
  text-decoration: none;
}
.ProductNotFound__back:hover {
  margin-left: -1rem;
}
.ProductNotFound__back:before {
  content: "";
  width: 3.6rem;
  height: 3.6rem;
  background: url(../../../images/back-to-prod.svg) no-repeat center center/contain;
  display: inline;
  margin-right: 1rem;
}
.ProductNotFound__product {
  border-bottom: solid 0.1rem rgba(115, 123, 127, 0.4);
  padding-bottom: 6rem;
  margin-bottom: 6rem;
}
.ProductNotFound__product .row {
  justify-content: center;
}
.ProductNotFound__product--links {
  display: flex;
  gap: 2rem;
}
.ProductNotFound__info {
  font-size: 1.8rem;
}
.ProductNotFound__info h2 {
  margin-bottom: 3rem;
}
.ProductNotFound__info__tag--wrapper {
  background: #f5f5f5;
  border-radius: 5rem;
  margin-bottom: 3rem;
  padding: 1.5rem 2rem;
  display: inline-flex;
}
.ProductNotFound__info .tag {
  background: #055aa5;
  color: #fff;
  padding: 0.8rem 2rem;
  display: inline-block;
  text-transform: capitalize;
  margin-right: 2rem;
  font-size: 1.6rem;
  border-radius: 5rem;
}
.ProductNotFound__info .tag:last-child {
  margin-right: 0;
}
.ProductNotFound__info .tag__only {
  margin-right: 0;
}
.ProductNotFound__info p {
  color: #737b7f;
}
.ProductNotFound__info p strong {
  color: #4a4a4a;
}
.ProductNotFound__posology strong {
  font-family: "TripletaBold";
  font-size: 2.1rem;
}
.ProductNotFound__posology ul {
  padding: 0;
  list-style: none;
}
.ProductNotFound__posology ul li {
  font-size: 2rem;
}
.ProductNotFound__posology.wborder {
  margin-top: 5rem;
  padding-top: 5rem;
  border-top: solid 0.1rem rgba(115, 123, 127, 0.4);
}
.ProductNotFound__another {
  margin-bottom: 10rem;
}
.ProductNotFound__another h1 {
  margin: 10rem 0 6rem 0;
}
.ProductNotFound__another__principle {
  font-size: 2rem;
  color: #737b7f;
}
.ProductNotFound__another__img {
  border-bottom: solid 0.1rem rgba(115, 123, 127, 0.4);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  height: 3rem;
  display: block;
  width: 100%;
}
.ProductNotFound__another__img-txt {
  padding-bottom: 1rem;
  display: block;
  height: auto;
  font-size: 3rem !important;
  font-style: italic;
  color: #055aa5;
}
.ProductNotFound__another__img-txt sup {
  font-family: "Mulish", sans-serif;
  font-size: 2rem;
}
.ProductNotFound__another__block {
  display: flex;
  flex-direction: column;
}
.ProductNotFound__another__block h2 {
  font-size: 2.4rem;
}
.ProductNotFound__another-btn {
  margin-top: 3rem;
}
.ProductNotFound .slick-next,
.ProductNotFound .slick-prev {
  width: 4rem;
  height: 4rem;
  z-index: 4;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
  position: absolute;
  top: 28%;
}
.ProductNotFound .slick-next:before,
.ProductNotFound .slick-prev:before {
  display: none;
}
.ProductNotFound .slick-next:hover,
.ProductNotFound .slick-prev:hover {
  opacity: 1;
}
.ProductNotFound .slick-next {
  background: url(../../../images/next-arrow.svg) no-repeat center center/contain;
}
.ProductNotFound .slick-prev {
  background: url(../../../images/prev-arrow.svg) no-repeat center center/contain;
}
.ProductNotFound__cpe {
  opacity: 0.7;
}
.ProductNotFound__cb {
  width: 40%;
}

@media (max-width: 576px) {
  .ProductNotFound__pic {
    margin-bottom: 0;
    height: 20rem;
  }
  .ProductNotFound__product {
    padding-bottom: 0rem;
    margin-bottom: 3rem;
  }
  .ProductNotFound__back {
    margin: 2rem auto 2rem auto;
  }
  .ProductNotFound__info {
    padding: 2rem;
    font-size: 1.8rem;
  }
  .ProductNotFound__info__tag--wrapper {
    padding: 0.8rem 1.2rem;
    display: block;
    background: transparent;
    border: 0;
  }
  .ProductNotFound__info .tag {
    margin-bottom: 1rem;
  }
  .ProductNotFound__posology {
    padding: 0 2rem;
  }
  .ProductNotFound__posology ul li {
    font-size: 1.6rem;
  }
  .ProductNotFound__posology strong {
    font-size: 1.6rem;
  }
  .ProductNotFound__posology h2 {
    font-size: 1.8rem;
  }
  .ProductNotFound__posology.wborder {
    margin-top: 2rem;
    padding-top: 2rem;
  }
  .ProductNotFound__another {
    margin-bottom: 0;
  }
  .ProductNotFound__another h1 {
    margin: 5rem 0 3rem 0;
    padding: 0 2rem;
  }
  .ProductNotFound__another__img {
    padding-bottom: 6rem;
  }
  .ProductNotFound__another__img-txt {
    padding-bottom: 2rem;
  }
  .ProductNotFound__another__block {
    display: block;
    margin-bottom: 3rem;
    padding: 2rem;
  }
  .ProductNotFound .slick-next,
  .ProductNotFound .slick-prev {
    top: 3%;
  }
  .ProductNotFound .slick-next {
    right: 2rem;
  }
  .ProductNotFound .slick-prev {
    left: 2rem;
  }
  .ProductNotFound__cb {
    width: 80%;
  }
}