.AdminEditPage {
  border-top: solid 0.1rem #e9eaee;
  margin-top: 15rem;
}
.AdminEditPage__NavContent {
  border-right: solid 0.1rem #e9eaee;
}
.AdminEditPage__Content {
  padding: 2rem;
}

.EditContent {
  border: solid 0.1rem #055aa5;
  margin: 4rem 0;
  padding: 3rem 2.4rem;
  position: relative;
}
.EditContent__active {
  border-width: 0.4rem;
}
.EditContent__boldtitle {
  font-family: "Tripleta", sans-serif;
  color: #4a4a4a;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  background: #f5f5f5;
  padding: 2rem;
  line-height: 2.8rem;
}
.EditContent__boldtitle strong {
  color: #055aa5;
  font-weight: 600;
}
.EditContent__title {
  font-size: 2.4rem;
  font-weight: 400;
  border: 0;
  margin: 0;
  margin-left: 2rem;
  font-family: "Mulish", sans-serif;
  color: #055aa5;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  padding-left: 3rem;
}
.EditContent__title:before {
  content: "";
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background: url("../../../images/title-icon.svg") no-repeat center center/contain;
  margin-right: 1rem;
  position: absolute;
  z-index: 0;
  left: 0;
}
.EditContent h2 {
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  position: absolute;
  top: -1.4rem;
  left: 1rem;
  background: #fff;
  padding: 0rem 1rem;
  color: #055aa5;
  font-size: 2rem;
}
.EditContent h2 small {
  margin-left: 0.8rem;
  color: #4a4a4a;
  font-size: 1.4rem;
}
.EditContent h3 {
  color: #cd202f;
  font-size: 2rem;
}

.AdminEdit__form h3 {
  color: #cd202f;
  font-family: "Tripleta", sans-serif;
}
.AdminEdit__form__title {
  font-size: 1.6rem;
  font-weight: 600;
  color: #055aa5;
}
.AdminEdit__form__label {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #737b7f;
}
.AdminEdit__form__label.en {
  color: #cd202f;
}
.AdminEdit__form__label strong,
.AdminEdit__form__label small {
  color: #055aa5;
}
.AdminEdit__form-leti-btn {
  background: transparent;
  padding: 1.4rem 3.2rem;
}
.AdminEdit__form .tox-tinymce {
  border: 1px solid #737b7f;
}
.AdminEdit__form .tox .tox-statusbar {
  display: none;
}
.AdminEdit__form .form-group {
  margin-top: 0;
}
.AdminEdit__form .form-group .form-control {
  height: 5.5rem;
  border-radius: 0;
  border-color: #737b7f;
  margin-bottom: 5rem;
}
.AdminEdit__message {
  display: inline-block;
  margin-left: 2rem;
  font-size: 1.4rem;
  color: #055aa5;
}

@media (max-width: 576px) {
  .AdminEditPage__Content {
    padding-top: 0rem;
  }
  .EditContent h2 {
    font-size: 1.6rem;
  }
  .EditContent__title {
    font-size: 1.8rem;
  }
}