.NewsPage__card {
  border: 0;
}
.NewsPage__card .card-time {
  font-size: 1.4rem;
  color: #737b7f;
}
.NewsPage__card .card-title {
  font-family: "TripletaBold";
  margin-bottom: 0;
  font-size: 2rem;
}
.NewsPage__card .card-subtitle {
  font-size: 1.6rem;
  margin-top: 1rem;
}
.NewsPage__card .card-footer {
  background: none;
  border: 0;
}
.NewsPage__card .card-footer .leti-btn {
  background: url(../../../images/plus.svg) no-repeat center/2.4rem;
  height: 5rem;
  width: 5rem;
}
.NewsPage__card .card-footer .leti-btn:hover {
  background: #055aa5 url(../../../images/plus-white.svg) no-repeat center/2.4rem;
}