.AdminProductPage {
  background: url(../../../images/cuidar-right-bg.svg) no-repeat right top;
  margin-top: 15rem;
}
.AdminProductPage__showproducts {
  font-size: 1.4rem;
  padding-bottom: 1rem;
}
.AdminProductPage__therapeutic-group {
  background: #f5f5f5;
  margin: 3rem 0;
  padding: 2rem;
}
.AdminProductPage__add {
  border: 0;
  background: transparent;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #055aa5;
  padding: 1.5rem;
  border: solid 0.2rem #055aa5;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin: -4rem auto 6rem auto;
}
.AdminProductPage__add:hover {
  background: #055aa5;
  color: white;
}
.AdminProductPage__add:hover:before {
  background: url(../../../images/add-white.svg) no-repeat center center/contain;
}
.AdminProductPage__add:before {
  content: "";
  width: 2rem;
  height: 2rem;
  display: inline-block;
  background: url(../../../images/add.svg) no-repeat center center/contain;
  margin-right: 1rem;
}
.AdminProductPage__bg {
  height: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdminProductPage__search {
  padding-left: 7rem;
  height: 6.4rem;
  font-size: 1.6rem;
  border-radius: 0;
  background: #fff url(../../../images/input-search.svg) no-repeat 2rem center/3.2rem;
}
.AdminProductPage__card {
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #055aa5;
  font-size: 1.6rem;
}
.AdminProductPage__img-top {
  height: 18rem;
  width: fit-content;
  display: block;
  margin: auto;
  max-width: -webkit-fill-available;
}
.AdminProductPage__img-bottom {
  height: 6rem;
  width: fit-content;
  display: block;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
.AdminProductPage__check {
  background: rgba(233, 234, 238, 0.8);
}
.AdminProductPage__create {
  display: block;
  transition: all 0.3s ease-in-out;
  height: 0;
  padding: 0;
  border: 0;
  background: #fff;
}
.AdminProductPage__create.show {
  height: auto;
  padding: 3rem 0;
  border-top: solid 0.1rem #e9eaee;
  border-bottom: solid 0.1rem #e9eaee;
  margin-bottom: 6rem;
}
.AdminProductPage small {
  color: #cd202f;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  display: block;
}
.AdminProductPage small.firebase {
  color: #000;
}
.AdminProductPage small.firebase-copy {
  margin-top: 10px;
  font-weight: 600;
  color: #055aa5;
  margin-bottom: 0;
}
.AdminProductPage .label {
  font-size: 1.6rem;
}
.AdminProductPage .form-control {
  margin-bottom: 1rem !important;
}
.AdminProductPage .card-title {
  font-family: "TripletaBold";
  font-size: 1.2rem;
  color: rgba(5, 90, 165, 0.6);
  padding-left: 1rem;
  margin-bottom: 0;
}
.AdminProductPage .card-footer {
  padding: 0;
  background: transparent;
}
.AdminProductPage .card-footer .leti-btn {
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  padding: 1rem;
  background: #055aa5;
  color: #fff;
}
.AdminProductPage .card-footer .leti-btn:hover {
  background: rgba(5, 90, 165, 0.8);
}
.AdminProductPage .card-body {
  position: relative;
}
.AdminProductPage .alert-danger {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  margin-top: -3rem;
  z-index: 1;
  font-size: 1.6rem;
  text-align: center;
  margin-left: -25%;
}
.AdminProductPage .alert-message {
  color: #cd202f;
  font-size: 14px;
  display: block;
}
.AdminProductPage .card-text {
  font-weight: 600;
  color: #000;
  font-size: 1.8rem;
}
.AdminProductPage .DropdownWithLabel {
  margin-top: 0 !important;
}