@charset "UTF-8";
input {
  overflow: hidden;
}

.custom-file-input {
  display: block;
  background: #fff;
  font-size: 1.6rem;
  border: solid 0.1rem #4a4a4a;
  height: 5.5rem;
  margin-bottom: 2rem;
  width: 100%;
}
.custom-file-input::-webkit-file-upload-button {
  display: none;
}
.custom-file-input:before {
  content: "Selecciona una imagen";
  display: inline-flex;
  align-content: center;
  padding: 0 2rem;
  padding-top: 0.6rem;
  background: #055aa5;
  border: 0;
  height: 5.5rem;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  margin-right: 1rem;
  padding-top: 1rem;
}
.custom-file-input.video:before {
  content: "Subir nuevo vídeo";
}