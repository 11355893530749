.Nav {
  position: relative;
}
.Nav__nav {
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  color: #737b7f;
  font-size: 1.6rem;
  line-height: 2rem;
}
.Nav .navbar-nav {
  display: flex;
  align-items: center;
  position: relative;
}
.Nav .nav-link {
  margin: 0 2rem;
  text-align: center;
  border-bottom: solid 0.3rem transparent;
}
.Nav .nav-link:hover {
  border-bottom: solid 0.3rem #055aa5;
}
.Nav .nav-link.active {
  pointer-events: none;
}
.Nav .nav-link__admin:hover {
  border-bottom: solid 0.3rem transparent;
}
.Nav__sub-nav {
  background: #e9eaee;
  position: absolute;
  top: -0.4rem;
  padding: 4rem;
  font-size: 1.6rem;
  display: block;
}
.Nav__sub-nav h3 {
  font-size: 2.4rem;
  margin-bottom: 3rem;
}
.Nav__sub-nav p {
  margin-bottom: 3rem;
  padding-right: 3rem;
}
.Nav__sub-nav a {
  color: #055aa5;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.Nav__sub-nav-cta:after {
  content: "";
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-top: 0.4rem;
  background: url(../../images/sub-nav-arrow.svg) no-repeat center center/contain;
  margin-left: 0.8rem;
  transition: all 200ms ease-in-out;
}
.Nav__sub-nav-cta:hover:after {
  margin-left: 1.6rem;
}
.Nav__sub-nav-info {
  border-right: solid 0.1rem #4a4a4a;
}
.Nav__sub-nav-anchors {
  padding-left: 3rem;
}
.Nav__sub-nav-anchors a:hover {
  color: #cd202f;
}

@media (max-width: 992px) {
  .Nav {
    position: absolute;
    top: 3rem;
    left: 2rem;
    transition: all 0.3s;
  }
  .Nav__nav {
    display: unset;
    top: 5rem;
    position: absolute;
    left: -2rem;
    width: 576px;
    background: #fff;
  }
  .Nav.thin {
    top: 2rem;
  }
  .Nav.thin .navbar-toggler {
    margin-top: 0;
  }
  .Nav .navbar-toggler {
    border: 0;
    outline: unset;
    margin-left: -2rem;
  }
  .Nav .navbar-toggler:focus {
    outline: none;
    border: 0;
  }
  .Nav .navbar-toggler .navbar-toggler-icon {
    background-image: url(../../images/toggler.svg);
  }
  .Nav .navbar-toggler .navbar-toggler-icon-active {
    background-image: url(../../images/close.svg);
  }
  .Nav .navbar-nav {
    align-items: flex-start;
    background: #e9eaee;
    height: 92vh;
    display: flex;
    justify-content: space-around;
    padding-bottom: 12rem;
    padding-top: 2rem;
  }
  .Nav .navbar-nav a {
    color: #055aa5 !important;
    display: inline-flex;
    position: relative;
    width: 58%;
  }
  .Nav .navbar-nav a:after {
    position: absolute;
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.7rem;
    background: url(../../images/sub-nav-arrow.svg) no-repeat 0 center/contain;
    right: 0;
  }
  .Nav__sub-nav {
    width: 576px !important;
    left: -2rem !important;
    height: 92vh;
    top: 5rem !important;
    padding: 0;
  }
  .Nav__sub-nav .row {
    justify-content: center;
  }
  .Nav__sub-nav-device {
    display: flex;
    align-items: center;
  }
  .Nav__sub-nav-device:before {
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;
    background: url(../../images/back-arrow.svg) no-repeat 0 center/contain;
  }
  .Nav__sub-nav-info {
    padding: 0.8rem 4rem 4rem 4rem;
    border-right: 0;
    border-bottom: solid 0.1rem #4a4a4a;
  }
  .Nav__sub-nav-info p {
    padding-right: 8rem;
  }
  .Nav__sub-nav-anchors {
    padding-top: 3rem;
    padding-left: 4rem;
  }
  .Nav__welcome {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    position: absolute;
    padding-top: 1.6rem;
    top: 0;
    left: 0rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    border-bottom: solid 0.1rem #737b7f;
    border-top: solid 0.1rem #737b7f;
    background-color: #fff;
    width: 100%;
    margin-top: 0rem;
  }
  .Nav__welcome-span {
    font-weight: 600;
    color: #cd202f;
    display: inline-block;
    margin-left: 0.4rem;
  }
  .Nav__welcome:before {
    content: "";
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    background: url(../../images/triangulo-rojo.svg) no-repeat center center/contain;
    background-size: 40%;
    display: inline-block;
    margin-right: 1rem;
    transform: rotate(180deg);
    border: solid 0.2rem #cd202f;
  }
  .Nav__logout {
    background: url(../../images/logout.svg) no-repeat center center/contain;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 57%;
  }
  .Nav__logout:hover {
    cursor: pointer;
  }
}