.Header {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 5;
  width: 100%;
}
.Header.thin {
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2);
}
.Header__logo {
  position: relative;
}
.Header__logo .navbar-brand {
  background: url(../../images/grupo-leti.svg) no-repeat center center/contain;
  width: 18rem;
  height: 6rem;
  display: block;
  margin: 2rem auto;
  transition: all 0.3s;
}
.Header__logo .navbar-brand.thin {
  width: 12rem;
  height: 5rem;
  margin: 1rem auto;
}
.Header__welcome {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  margin-top: -1.5rem;
  margin-left: 2rem;
}
.Header__welcome-span {
  font-weight: 600;
  color: #cd202f;
  display: inline-block;
  margin-left: 0.4rem;
}
.Header__welcome:before {
  content: "";
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background: url(../../images/triangulo-rojo.svg) no-repeat center center/contain;
  background-size: 40%;
  display: inline-block;
  margin-right: 1rem;
  transform: rotate(180deg);
  border: solid 0.2rem #cd202f;
}
.Header__logout, .Header__search {
  border: 0;
  width: 3rem;
  height: 3rem;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -1.5rem;
}
.Header__search {
  background: url(../../images/search.svg) no-repeat center center/contain;
  right: 9rem;
  padding-right: 2rem;
}
.Header__search:hover {
  cursor: pointer;
}
.Header__search-close {
  background: url(../../images/close.svg) no-repeat center center/contain;
}
.Header__logout {
  background: url(../../images/logout.svg) no-repeat center center/contain;
  margin-right: 2rem;
}
.Header__logout:hover {
  cursor: pointer;
}
.Header .language-wrapper {
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  top: 50%;
  margin-top: -1.5rem;
  border-left: solid 0.1rem #055aa5;
  padding-left: 0.8rem;
}
.Header .language-btn {
  border: 0;
  background-color: transparent;
}
.Header .language-btn strong {
  color: #055aa5;
}

@media (max-width: 576px) {
  .Header {
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);
  }
  .Header__welcome {
    padding-top: 1.6rem;
    top: 0;
    left: 0rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    border-bottom: solid 0.1rem #737b7f;
    border-top: solid 0.1rem #737b7f;
    background-color: #fff;
    width: 100%;
    margin-top: 0rem;
  }
  .Header__logo .navbar-brand {
    width: 12rem;
    height: 4rem;
  }
  .Header__logout {
    right: 2rem;
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
  }
  .Header__search {
    width: 0;
    height: 2.4rem;
    right: 10rem;
    margin-top: -1rem;
  }
  .Header .language-wrapper {
    border-left: 0;
  }
}