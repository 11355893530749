.Banner {
  margin-bottom: 6rem;
}
.Banner__image {
  background: url(../../../../images/banner-modal.png) no-repeat center center/cover;
  height: 1040px;
  width: 100%;
}

@media (max-width: 1200px) {
  .Banner__image {
    height: 650px;
  }
}
@media (max-width: 1024px) {
  .Banner__image {
    height: 650px;
  }
}
@media (max-width: 992px) {
  .Banner__image {
    height: 500px;
  }
}
@media (max-width: 800px) {
  .Banner__image {
    height: 370px;
  }
}
@media (max-width: 750px) {
  .Banner__image {
    height: 350px;
  }
}
@media (max-width: 576px) {
  .Banner__image {
    height: calc(50vh - 100px);
  }
}
@media (max-width: 480px) {
  .Banner__image {
    height: calc(50vh - 80px);
  }
}