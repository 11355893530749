.Timeline {
  position: relative;
  padding: 0;
  background: #f5f5f5;
}
.Timeline__image {
  height: 65rem;
  background-color: #fff;
}
.Timeline__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  position: relative;
}
.Timeline__info:after {
  content: "";
  width: 100%;
  height: 4rem;
  bottom: 0rem;
  background: #e9eaee;
  position: absolute;
  right: 0;
  z-index: 0;
}
.Timeline__year {
  font-family: "Tripleta";
  font-size: 13rem;
  color: #055aa5;
  display: block;
  margin-top: 12rem;
}
.Timeline__desc {
  padding: 2rem 0 2rem 4rem;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 5rem;
}
.Timeline .slick-slider {
  position: relative;
  display: block;
}
.Timeline .slick-slider .slick-next,
.Timeline .slick-slider .slick-prev {
  width: 4rem;
  height: 4rem;
  z-index: 4;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
  position: absolute;
}
.Timeline .slick-slider .slick-next:hover,
.Timeline .slick-slider .slick-prev:hover {
  opacity: 1;
}
.Timeline .slick-slider .slick-next:before,
.Timeline .slick-slider .slick-prev:before {
  content: "";
}
.Timeline .slick-slider .slick-next {
  background: url(../../../../images/next-arrow.svg) no-repeat center center/contain;
}
.Timeline .slick-slider .slick-prev {
  background: url(../../../../images/prev-arrow.svg) no-repeat center center/contain;
}

@media (max-width: 576px) {
  .Timeline__image {
    height: 38rem;
    background-size: 100% !important;
    background-position: 1rem bottom !important;
  }
  .Timeline__year {
    font-size: 7rem;
    margin-left: 2rem;
    margin-top: 7rem;
  }
  .Timeline__desc {
    padding: 2rem 4rem 0 4rem;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 8rem;
  }
  .Timeline .slick-next {
    transform: translate(-24rem, 8rem) !important;
  }
  .Timeline .slick-prev {
    transform: translate(6rem, 8rem) !important;
  }
  .Timeline__info:after {
    content: unset;
  }
}