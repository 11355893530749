.LandingRRSS {
  margin: 6rem auto;
  height: 50rem;
  border-radius: 3rem;
  width: calc(100% - 12rem);
  color: white;
  padding: 9rem 6.5rem;
}
.LandingRRSS h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 5.2rem;
  font-weight: 400;
  line-height: 5rem;
}
.LandingRRSS h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}
.LandingRRSS a {
  color: white;
}
.LandingRRSS a:hover {
  text-decoration: none;
}

@media (max-width: 48rem) {
  .LandingRRSS {
    margin: 0 1.6rem;
    width: calc(100% - 3.2rem);
    padding: 2.4rem;
    background-position: center bottom !important;
  }
  .LandingRRSS h2,
  .LandingRRSS a {
    font-size: 1.4rem;
    text-shadow: none;
  }
}