.EditFAQItems {
  padding-top: 3rem !important;
}
.EditFAQItems-faqs {
  display: flex;
  align-items: center;
  margin: 0 !important;
}
.EditFAQItems-faqs h5 {
  font-size: 1.6rem;
  margin: 0;
}