.Map {
  background: #f5f5f5;
  margin-top: 25rem;
  position: relative;
}
.Map__image {
  background: url(../../../../images/tech-map-image.png) no-repeat center center/cover;
  height: 50rem;
  padding: 0;
  position: absolute;
  left: 0;
  top: -25rem;
}
.Map__image iframe {
  height: 50rem;
}
.Map__info {
  font-size: 2.4rem;
  padding: 10rem 0;
}
.Map__info p {
  width: 30rem;
  padding-left: 3rem;
}

@media (max-width: 576px) {
  .Map {
    margin-top: 13.5rem;
  }
  .Map__image {
    height: 35rem;
    position: static;
  }
  .Map__image iframe {
    height: 35rem;
  }
  .Map__info {
    font-size: 1.6rem;
    padding: 4rem 2rem;
  }
  .Map__info p {
    width: unset;
    padding: 0;
  }
}