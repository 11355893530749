.ShowEditModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ShowEditModal .label {
  font-size: 1.6rem;
  color: #737b7f;
}
.ShowEditModal .leti-btn:hover {
  background: #055aa5;
}
.ShowEditModal__container {
  background: #fff;
  padding: 2rem 4rem;
  font-size: 2rem;
  position: relative;
  overflow: scroll;
  max-height: 96rem;
  height: auto;
  padding-top: 4rem;
}
.ShowEditModal__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border: solid 0.1rem #055aa5;
  background: url("../../../../images/close.svg") no-repeat center/contain;
  background-size: 50%;
}
.ShowEditModal__close:hover {
  border: solid 0.3rem #055aa5;
}
.ShowEditModal__news-title {
  color: #055aa5;
  display: block;
  font-size: 3rem;
}
.ShowEditModal__thumbnail {
  position: relative;
  padding-left: 14rem;
}
.ShowEditModal__thumbnail-img {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 0;
  left: 1rem;
}

@media (max-width: 576px) {
  .ShowEditModal__thumbnail {
    padding-left: 6rem;
    margin-bottom: 0 !important;
    margin-top: 3rem;
  }
  .ShowEditModal__thumbnail h1 {
    font-size: 2rem;
  }
  .ShowEditModal__thumbnail h1 span {
    font-size: 1.6rem;
  }
  .ShowEditModal__thumbnail-img {
    width: 4rem;
    height: 4rem;
  }
}