.Banner__IYD {
  position: relative;
}
.Banner__IYD-logo {
  margin-top: 6rem;
  display: block;
}
.Banner__IYD__title {
  height: 70rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.Banner__IYD__title h3 {
  font-size: 3rem;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  margin: 1rem auto 10rem auto;
}
.Banner__IYD__bg {
  position: absolute;
  width: 54rem;
  height: 54rem;
  right: -6rem;
  top: -10rem;
  transform: rotate(0deg);
}
.Banner__IYD__blue {
  position: absolute;
  width: 44rem;
  height: 44rem;
  background: url(../../../../images/leti-blue-banner.png) no-repeat center/contain;
  right: -6rem;
  top: 12rem;
  transform: rotate(0deg);
}

@media (max-width: 576px) {
  .Banner__IYD-logo {
    width: 28rem;
    margin-top: 5rem;
  }
  .Banner__IYD__title {
    height: 85rem;
    justify-content: flex-start;
    margin-top: 6rem;
  }
  .Banner__IYD__title h3 {
    font-size: 2rem;
    margin: 0rem auto 8rem auto;
    line-height: 2.8rem;
  }
  .Banner__IYD__bg {
    width: 38rem;
    height: 38rem;
    right: -6rem;
    top: 32rem;
  }
  .Banner__IYD__blue {
    width: 30rem;
    height: 30rem;
    right: -6rem;
    top: 48rem;
  }
}