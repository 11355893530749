.Letter {
  margin: 10rem auto;
}
.Letter__title {
  text-align: center;
  margin-bottom: 6rem;
}
.Letter__title p {
  font-size: 6rem !important;
  line-height: 7rem !important;
}
.Letter p {
  font-size: 2.4rem;
  line-height: 3rem;
  margin-bottom: 3rem;
}
.Letter__img {
  width: 16rem;
  display: block;
  margin: 4rem auto;
}

@media (max-width: 576px) {
  .Letter {
    margin: 0;
  }
  .Letter__title {
    margin-bottom: 3rem;
  }
  .Letter__title p {
    font-size: 3.6rem;
  }
  .Letter p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}