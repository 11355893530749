.LoginPage {
  position: relative;
  padding: 10rem 0 20rem 0;
}
.LoginPage .leti-red-triangle {
  background: url(../../../images/triangulo-rojo-stroke.svg) no-repeat center center/contain;
  width: 95rem;
  height: 95rem;
  position: absolute;
  right: -40rem;
  bottom: calc(var(--section-padding) * -1);
  transform: rotate(50deg);
  z-index: -1;
}
.LoginPage .leti-blue-triangle {
  background: url(../../../images/triangulo-azul-stroke.svg) no-repeat center center/contain;
  width: 80rem;
  height: 70rem;
  position: absolute;
  bottom: calc(var(--section-padding) * -1);
  right: 0;
  transform: rotate(-150deg);
  z-index: -1;
}

@media (max-width: 576px) {
  .LoginPage {
    padding: 5rem 0;
  }
  .LoginPage .leti-btn {
    background: #fff;
  }
  .LoginPage .leti-red-triangle {
    width: 30rem;
    height: 30rem;
    right: 0;
    bottom: -10rem;
  }
  .LoginPage .leti-blue-triangle {
    width: 50rem;
    height: 40rem;
    right: 0;
    bottom: -5rem;
  }
}