.BiocontrolledInfo-Clinical {
  margin-top: 12rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.BiocontrolledInfo-Clinical h1 {
  font-size: 6.4rem;
  color: #1A1A1A;
  font-weight: 400;
}
.BiocontrolledInfo-Clinical p {
  font-size: 2.8rem;
  color: #737373;
  line-height: 1.5;
  font-weight: 300;
}
.BiocontrolledInfo-Clinical p b, .BiocontrolledInfo-Clinical p strong {
  color: #1A1A1A;
}
.BiocontrolledInfo-Clinical--image {
  border-radius: 10rem;
  height: 62rem;
  background: url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fbiocontrolled-banner.jpg?alt=media&token=e65368f8-8ca7-4160-9852-d0d026e49ff6) no-repeat center/cover;
}
.BiocontrolledInfo-Clinical--logos {
  height: 23rem;
  background: url(../../../../../images/biocontrolled-clinical-logos.svg) no-repeat center right/50%;
  margin-top: 4rem;
  margin-bottom: 10rem;
}
.BiocontrolledInfo-Clinical--info {
  font-size: 4.8rem;
  color: #737373;
  font-weight: bold;
  line-height: 6.4rem;
  margin-top: 7rem;
}
.BiocontrolledInfo-Clinical--info p {
  font-size: 4.8rem;
  color: #737373;
  font-weight: bold;
  line-height: 6.4rem;
  margin-top: 7rem;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Clinical {
    padding: 0 2rem;
    margin: 2.4rem auto;
  }
  .BiocontrolledInfo-Clinical h1 {
    font-size: 3.2rem;
    font-weight: 900;
  }
  .BiocontrolledInfo-Clinical p {
    font-size: 1.6rem;
  }
  .BiocontrolledInfo-Clinical--image {
    height: 32rem;
    border-radius: 4rem;
  }
  .BiocontrolledInfo-Clinical--info {
    font-size: 3rem;
    text-align: center;
  }
  .BiocontrolledInfo-Clinical--logos {
    background-size: contain;
    background-position: center;
    height: 10rem;
    margin-bottom: 2rem;
  }
}