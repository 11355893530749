:root {
  --section-padding: 18rem;
}

.Nosotros {
  position: relative;
  margin: 10rem auto 15rem auto;
}
.Nosotros__valor {
  margin: 6rem 0;
  line-height: 3.8rem;
}
.Nosotros__valor strong {
  color: #055aa5;
}
.Nosotros .leti-counter {
  position: absolute;
  top: 6rem;
  right: 8rem;
  font-family: "TripletaBold";
  font-size: 8rem;
}
.Nosotros .leti-counter .value {
  font-size: 16rem;
  display: inline;
  font-weight: 900;
  letter-spacing: -1rem;
}
.Nosotros .leti-btn {
  background: #fff;
}
.Nosotros .leti-btn:hover {
  background: #055aa5;
}
.Nosotros .leti-red-triangle {
  background: url(../../../../images/triangulo-rojo-stroke.svg) no-repeat center center/contain;
  width: 70rem;
  height: 70rem;
  position: absolute;
  right: -70rem;
  bottom: calc(var(--section-padding) * -1);
  transform: rotate(50deg);
}
.Nosotros .leti-blue-triangle {
  background: url(../../../../images/triangulo-azul-stroke.svg) no-repeat center center/contain;
  width: 40rem;
  height: 40rem;
  position: absolute;
  bottom: calc(var(--section-padding) * -1);
  right: -40rem;
  transform: rotate(-150deg);
}

@media (max-width: 576px) {
  .Nosotros {
    margin: 0rem auto 20rem auto;
    font-size: 2rem;
  }
  .Nosotros__valor {
    margin: 14rem 0 6rem 0;
  }
  .Nosotros .leti-counter {
    position: absolute;
    top: 3rem;
    left: 2rem;
    font-size: 4rem;
  }
  .Nosotros .leti-counter .value {
    font-size: 11rem;
  }
  .Nosotros .row {
    justify-content: center;
  }
  .Nosotros .leti-red-triangle {
    width: 30rem;
    height: 30rem;
    right: -5rem;
  }
  .Nosotros .leti-blue-triangle {
    width: 25rem;
    height: 25rem;
    right: 0;
  }
  .Nosotros .leti-blue-triangle,
  .Nosotros .leti-red-triangle {
    top: 35rem;
    z-index: -1;
  }
  .Nosotros [class*=css-] {
    z-index: -1;
  }
}