.Search {
  position: fixed;
  left: 0;
  top: -10rem;
  width: 100%;
  background: #f5f5f5;
  z-index: 2;
  height: 0;
  display: flex;
  z-index: 10;
  transition: all 300ms ease-in-out;
}
.Search.show {
  top: 14rem;
  height: 32rem;
}
.Search.show.thin {
  top: 10rem;
}
.Search__form {
  width: 100%;
}
.Search__form-input {
  border-radius: 0;
  border: 0;
  border-bottom: solid 0.1rem #4a4a4a;
  font-size: 2.8rem;
  background: url(../../../images/input-search.svg) no-repeat 1rem 0rem;
  background-size: 3rem;
  padding-left: 5rem;
  padding-bottom: 2rem;
  width: 100%;
}
.Search__form-input:focus {
  background-color: transparent;
  outline: none;
}

@media (max-width: 576px) {
  .Search.show {
    top: 6rem;
    height: 10rem;
  }
  .Search.show.thin {
    top: 5rem;
  }
  .Search__form-input {
    background-position: 1rem 0.5rem;
    background-size: 2.4rem;
    font-size: 1.6rem;
    width: 100%;
  }
}