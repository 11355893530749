.Goals {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10rem 0;
}
.Goals [class*=cells] {
  position: absolute;
}
.Goals__number {
  background: #055aa5;
  color: #fff;
  width: 5.7rem;
  height: 5.7rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.Goals__number:after {
  content: "";
  width: 7.7rem;
  height: 7.7rem;
  border: solid 0.1rem #055aa5;
  display: block;
  position: absolute;
  top: -1rem;
  left: -1rem;
  border-radius: 14rem;
}
.Goals__info {
  margin-left: 10rem;
}
.Goals__subtitle {
  font-family: "tripletaBold";
  font-size: 3rem;
  display: flex;
  align-items: center;
}
.Goals__desc {
  font-size: 2.4rem;
  color: #737b7f;
  margin-bottom: 9rem;
  padding-right: 10rem;
}
.Goals__desc strong {
  color: #055aa5;
  font-weight: unset;
}
.Goals__cells-001 {
  width: 19rem;
  height: 19rem;
  left: -4rem;
  bottom: -4rem;
}
.Goals__cells-002 {
  width: 7rem;
  height: 7rem;
  top: 7rem;
  left: 7rem;
}
.Goals__cells-003 {
  width: 1.8rem;
  height: 1.8rem;
  top: -6rem;
  right: 15rem;
}
.Goals__cells-004 {
  width: 6.5rem;
  height: 6.5rem;
  top: 3.2rem;
  left: 35%;
}
.Goals__cells-005 {
  width: 3rem;
  height: 3rem;
  left: 30rem;
  top: 25rem;
}
.Goals__cells-006 {
  width: 5rem;
  height: 5rem;
  right: 6rem;
  top: 30rem;
}
.Goals__cells-007 {
  width: 3rem;
  height: 3rem;
  left: 87rem;
  top: 34rem;
}

@media (max-width: 1180px) {
  .Goals__cells-002 {
    top: 17rem;
  }
}
@media (max-width: 576px) {
  .Goals__title {
    margin-left: 0rem;
  }
  .Goals__title h1 {
    margin-left: 2rem;
    font-size: 3.2rem;
  }
  .Goals__number {
    margin-left: 2rem;
    width: 4.7rem;
    height: 4.7rem;
    position: relative;
  }
  .Goals__number:after {
    width: 5.7rem;
    height: 5.7rem;
    top: -0.5rem;
    left: -0.5rem;
  }
  .Goals__info {
    margin-left: 2rem;
  }
  .Goals__subtitle {
    font-size: 2.2rem;
  }
  .Goals__title {
    margin-bottom: 10rem;
  }
  .Goals__desc {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    padding-right: 2rem;
  }
  .Goals__cells-001, .Goals__cells-002 {
    display: none;
  }
  .Goals__cells-005 {
    left: 5rem;
    top: 15rem;
  }
  .Goals__cells-006 {
    top: 10rem;
  }
}