.Banner__OurPeople {
  background: #fbfbfb;
  position: relative;
  overflow: hidden;
}
.Banner__OurPeople__title {
  height: 70rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Banner__OurPeople__title strong {
  color: #055aa5;
  font-weight: unset;
}
.Banner__OurPeople__title h1 {
  font-size: 5.4rem;
  margin-bottom: 4rem;
}
.Banner__OurPeople__bg [class*=css-] {
  height: 90rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
}
.Banner__OurPeople__bg [class*=css-] img {
  height: 70%;
}
.Banner__OurPeople .leti-red-triangle,
.Banner__OurPeople .leti-blue-triangle {
  position: absolute;
  z-index: 0;
  transform: rotate(0);
}
.Banner__OurPeople .leti-red-triangle {
  background: url(../../../../images/triangulo-rojo-stroke.svg) no-repeat center center/contain;
  width: 75rem;
  height: 75rem;
  right: -8rem;
  top: 0rem;
}
.Banner__OurPeople .leti-blue-triangle {
  background: url(../../../../images/triangulo-azul-stroke.svg) no-repeat center center/contain;
  width: 55rem;
  height: 55rem;
  right: 5rem;
  top: 15rem;
}

@media (max-width: 1180px) {
  .Banner__OurPeople .leti-red-triangle {
    right: -15rem;
  }
}
@media (max-width: 576px) {
  .Banner__OurPeople__title {
    height: 70rem;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Banner__OurPeople__title h1 {
    margin-top: 3rem;
    font-size: 3.2rem;
  }
  .Banner__OurPeople__title p {
    font-size: 2rem;
  }
  .Banner__OurPeople__bg [class*=css-] {
    right: 10%;
  }
  .Banner__OurPeople__bg [class*=css-] img {
    height: 45%;
  }
  .Banner__OurPeople .leti-red-triangle {
    width: 35rem;
    height: 35rem;
    right: -5%;
    top: 55%;
  }
  .Banner__OurPeople .leti-blue-triangle {
    width: 35rem;
    height: 35rem;
    right: -25%;
    top: 65%;
  }
}