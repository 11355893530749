.Careers strong {
  color: #055aa5;
  font-weight: unset;
}

@media (max-width: 1180px) {
  .Careers {
    margin-top: 10rem;
  }
}
@media (max-width: 576px) {
  .Careers {
    padding: 2rem;
    font-size: 1.6rem;
  }
  .Careers .leti-btn {
    margin: 2rem 0;
  }
}