.NewsSingle__Banner {
  color: #fff;
  height: 45rem;
  display: flex;
  align-items: center;
  position: relative;
}
.NewsSingle__Banner:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(5, 90, 165, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.NewsSingle__Banner__container {
  color: #fff;
  text-shadow: 0 0 3.2rem black;
  position: absolute;
  left: 6rem;
}
.NewsSingle__Banner__container span {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.NewsSingle__content {
  margin: 10rem auto 16rem auto;
  color: #737b7f;
  line-height: 3.6rem;
  position: relative;
}
.NewsSingle__content-desc {
  position: relative;
}
.NewsSingle__content-desc strong {
  color: #055aa5;
  font-weight: 400;
}
.NewsSingle__content-desc img {
  width: 100%;
  height: auto;
}
.NewsSingle__content-desc table {
  border: 0;
}
.NewsSingle__content-desc a {
  color: #055aa5;
}
.NewsSingle__rrss {
  position: absolute;
  top: 0.8rem;
  left: 4rem;
}
.NewsSingle__rrss .facebook,
.NewsSingle__rrss .whatsapp,
.NewsSingle__rrss .linkedin {
  display: block;
  width: 3rem;
  height: 3rem;
  margin-bottom: 2rem;
}
.NewsSingle__rrss .facebook {
  background: url(../../../images/facebook-single.svg) no-repeat center/cover;
}
.NewsSingle__rrss .whatsapp {
  background: url(../../../images/whatsapp-single.svg) no-repeat center/cover;
}
.NewsSingle__rrss .linkedin {
  background: url(../../../images/linkedin-single.svg) no-repeat center/cover;
}
.NewsSingle__rrss-bottom {
  bottom: -12rem;
  right: 0;
  top: unset;
  left: unset;
}
.NewsSingle__rrss-bottom .facebook,
.NewsSingle__rrss-bottom .whatsapp,
.NewsSingle__rrss-bottom .linkedin {
  display: inline-block;
  margin-right: 2rem;
}
.NewsSingle__related {
  margin-bottom: 10rem;
}
.NewsSingle__related h1 {
  margin-bottom: 6rem;
}

@media (max-width: 576px) {
  .NewsSingle__Banner__container {
    position: absolute;
    left: 1rem;
    width: fit-content;
  }
  .NewsSingle__content {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .NewsSingle__related {
    margin-bottom: 0rem;
  }
  .NewsSingle__related h1 {
    margin-bottom: 6rem;
    padding: 0 2rem;
  }
  .NewsSingle__related .card-body {
    padding: 2rem;
  }
  .NewsSingle__related .card-footer {
    padding: 0 2rem;
  }
  .NewsSingle__related .card-footer .leti-btn {
    margin-bottom: 4rem;
  }
  .NewsSingle__rrss {
    position: absolute;
    top: -5.2rem;
    left: unset;
    right: 2rem;
    display: flex;
  }
  .NewsSingle__rrss:before {
    content: "Comparte:";
    color: #055aa5;
    margin-right: 2rem;
    font-weight: 600;
    top: 0;
  }
  .NewsSingle__rrss .facebook,
  .NewsSingle__rrss .whatsapp,
  .NewsSingle__rrss .linkedin {
    display: inline-block;
    margin-right: 1.6rem;
  }
  .NewsSingle__rrss-bottom {
    bottom: -12rem;
    right: 0;
    top: unset;
    left: unset;
  }
  .NewsSingle__rrss-bottom .facebook,
  .NewsSingle__rrss-bottom .whatsapp,
  .NewsSingle__rrss-bottom .linkedin {
    margin-right: 1.6rem;
  }
}