.Certificate {
  position: relative;
}
.Certificate__visible {
  overflow: visible;
}
.Certificate__img {
  padding: 0rem;
  position: absolute;
  left: 0;
  width: 45%;
  height: 55rem;
  text-align: center;
  overflow: hidden;
  z-index: 0;
}
.Certificate__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Certificate__info {
  height: 55rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 6rem;
}
.Certificate__slide {
  height: 45rem;
}

@media (max-width: 576px) {
  .Certificate__img {
    width: 100%;
    height: 20rem;
    position: static;
    padding: 0;
    font-size: 1.6rem;
  }
  .Certificate__info {
    height: 27rem;
    margin-bottom: 3rem;
    font-size: 1.6rem;
  }
  .Certificate__info p {
    padding: 0;
  }
}