.Banner__ourCompanies {
  background: #fbfbfb;
  position: relative;
  overflow: hidden;
}
.Banner__ourCompanies__title {
  height: 70rem;
  display: flex;
  align-items: center;
}
.Banner__ourCompanies__title strong {
  color: #055aa5;
  font-weight: 500;
}
.Banner__ourCompanies__bg [class*=css-] {
  height: 70rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: flex-end;
}
.Banner__ourCompanies__bg [class*=css-] img {
  height: 90%;
}
.Banner__ourCompanies .leti-red-triangle,
.Banner__ourCompanies .leti-blue-triangle {
  position: absolute;
  z-index: 0;
  transform: rotate(0);
}
.Banner__ourCompanies .leti-red-triangle {
  background: url(../../../../images/triangulo-rojo-stroke.svg) no-repeat center center/contain;
  width: 75rem;
  height: 75rem;
  right: -8rem;
  top: 0rem;
}
.Banner__ourCompanies .leti-blue-triangle {
  background: url(../../../../images/triangulo-azul-stroke.svg) no-repeat center center/contain;
  width: 55rem;
  height: 55rem;
  right: 5rem;
  top: 15rem;
}

@media (max-width: 1180px) {
  .Banner__ourCompanies__title h1 {
    font-size: 5rem;
  }
  .Banner__ourCompanies .leti-red-triangle {
    right: -156px;
  }
}
@media (max-width: 576px) {
  .Banner__ourCompanies__title {
    height: 70rem;
    align-items: flex-start;
  }
  .Banner__ourCompanies__title h1 {
    margin-top: 3rem;
  }
  .Banner__ourCompanies__bg [class*=css-] {
    right: -15%;
  }
  .Banner__ourCompanies__bg [class*=css-] img {
    height: 55%;
  }
  .Banner__ourCompanies .leti-red-triangle {
    width: 35rem;
    height: 35rem;
    right: -5%;
    top: 55%;
  }
  .Banner__ourCompanies .leti-blue-triangle {
    width: 35rem;
    height: 35rem;
    right: -25%;
    top: 65%;
  }
}