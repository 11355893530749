.LandigFooter {
  border-top: solid 2px #003595;
  padding-top: 2.4rem;
  margin: 0 auto;
  width: calc(100% - 12rem);
  margin-bottom: 4rem;
}
.LandigFooter h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 5.2rem;
  font-weight: 400;
  line-height: 5rem;
}
.LandigFooter h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.1rem;
}
.LandigFooter a {
  color: #003595;
  text-decoration: none;
  font-family: "Work Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.1rem;
}
.LandigFooter a:hover {
  text-decoration: none;
}
.LandigFooter ul {
  list-style: none;
}
.LandigFooter ul li:first-child a {
  font-weight: bold;
}
.LandigFooter ul a {
  font-weight: 400;
}
.LandigFooter-rrss {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}
.LandigFooter-rrss-icons {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 2rem;
  gap: 12px;
  flex-direction: row;
  margin-top: 36px;
  margin-right: 6px;
}

@media (max-width: 48rem) {
  .LandigFooter {
    border-top: solid 2px #003595;
    padding: 0;
    padding-top: 2.4rem;
    margin: 0 1.6rem;
    width: calc(100% - 3.2rem);
    margin-bottom: 3rem;
    margin-top: 3rem;
    text-align: center;
  }
  .LandigFooter ul {
    padding: 0;
  }
  .LandigFooter-rrss {
    align-items: center;
  }
  .LandigFooter-rrss img {
    margin: 3rem auto;
    width: 31.6rem !important;
  }
  .LandigFooter-rrss-icons img {
    width: unset !important;
    margin: 0;
  }
}