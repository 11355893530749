@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@font-face {
  font-family: "Tripleta";
  src: url("../fonts/TripletaLight.woff2") format("woff2"), url("../fonts/TripletaLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "TripletaBold";
  src: url("../fonts/TRIPLETAExtraBold.woff2") format("woff2"), url("../fonts/TRIPLETAExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  font-size: 10px;
}

body {
  font-size: 2.4rem;
  line-height: 3rem;
  color: #4a4a4a;
  font-family: "Mulish", sans-serif;
}

.blue-text {
  color: #055aa5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Tripleta";
}

h1 {
  font-size: 6rem;
}

h2 {
  font-family: "Mulish";
  font-size: 3rem;
  font-weight: 700;
}

@media (max-width: 576px) {
  h1 {
    font-size: 3.6rem;
  }
}
body {
  margin-top: 14rem;
  scroll-behavior: smooth;
}

.leti-btn {
  color: #055aa5;
  border: solid 0.1rem #055aa5;
  font-weight: 400;
  text-decoration: none;
  font-size: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2.4rem;
  transition: all 200ms ease-in-out;
  background: transparent;
}
.leti-btn:hover {
  cursor: pointer;
  background: #055aa5;
  color: #fff;
}
.leti-btn.delete {
  border-color: #cd202f;
  color: #fff;
  background: #cd202f;
  position: relative;
  transition: all 200ms ease-in-out;
}
.leti-btn.delete:hover {
  background: #cd202f !important;
}
.leti-btn.delete:hover:before {
  content: "¿Estás seguro?";
  position: absolute;
  background: #cd202f;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leti-btn.hidden-label {
  margin-top: 4rem;
  height: 5.5rem;
}

.alert {
  animation: alerta 3s ease-in-out forwards;
  display: block;
  opacity: 0;
  position: absolute;
  top: -2.6rem;
  right: 2rem;
  font-size: 1.6rem;
  color: #055aa5;
  background: #fff;
  border: solid 0.1rem #055aa5;
  border-radius: 0;
}

.tox {
  margin-bottom: 3rem;
}

.parallax {
  transition: transform 10s cubic-bezier(0, 1, 0.5, 1);
  transform: rotate(-150deg);
}

.img-success {
  font-size: 1.4rem;
  position: absolute;
  margin-top: -2rem;
  color: #055aa5;
}

small {
  color: #cd202f;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 600;
  display: block;
  margin-bottom: 1rem;
}

.smalltag label {
  font-size: 1.4rem !important;
}

@keyframes alerta {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 576px) {
  body {
    margin-top: 8rem;
  }
  .leti-btn {
    display: flex;
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
  }
}