.AdminNewsPage {
  background: url(../../../images/cuidar-right-bg.svg) no-repeat right top;
  position: relative;
  margin-top: 15rem;
}
.AdminNewsPage__close {
  content: "";
  display: block;
  width: 6rem;
  height: 6rem;
  background: url(../../../images/close.svg) no-repeat center/3rem;
  position: absolute;
  right: 2rem;
  top: 6rem;
  border: solid 0.2rem #055aa5;
}
.AdminNewsPage__close:hover {
  cursor: pointer;
  border-width: 0.4rem;
}
.AdminNewsPage__shownews {
  font-size: 1.4rem;
  padding-bottom: 1rem;
}
.AdminNewsPage__add {
  border: 0;
  background: transparent;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color: #055aa5;
  padding: 1.5rem;
  border: solid 0.2rem #055aa5;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  margin: -4rem auto 6rem auto;
}
.AdminNewsPage__add:hover {
  background: #055aa5;
  color: white;
}
.AdminNewsPage__add:hover:before {
  background: url(../../../images/add-white.svg) no-repeat center center/contain;
}
.AdminNewsPage__add:before {
  content: "";
  width: 2rem;
  height: 2rem;
  display: inline-block;
  background: url(../../../images/add.svg) no-repeat center center/contain;
  margin-right: 1rem;
}
.AdminNewsPage__bg {
  height: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdminNewsPage__search {
  padding-left: 7rem;
  height: 6.4rem;
  font-size: 1.6rem;
  border-radius: 0;
  background: #fff url(../../../images/input-search.svg) no-repeat 2rem center/3.2rem;
}
.AdminNewsPage__card {
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  border: solid 0.1rem #055aa5;
  font-size: 1.6rem;
}
.AdminNewsPage__img-title {
  position: absolute;
  z-index: 1;
  bottom: 0;
  padding: 1rem;
  background: #fff;
  color: #055aa5;
  width: 100%;
  margin-bottom: 0;
  font-size: 1.6rem;
}
.AdminNewsPage__img-tag {
  display: inline;
  background-color: #055aa5;
  color: #fff;
  width: auto;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  border-radius: 4rem;
  margin-right: 0.6rem;
}
.AdminNewsPage__img-alltags {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0.6rem;
}
.AdminNewsPage__img-top {
  height: 18rem;
  width: fit-content;
  display: block;
  margin: auto;
}
.AdminNewsPage__img-bottom {
  height: 6rem;
  width: fit-content;
  display: block;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}
.AdminNewsPage__check {
  background: rgba(233, 234, 238, 0.8);
}
.AdminNewsPage__create {
  display: block;
  transition: all 0.3s ease-in-out;
  height: 0;
  padding: 0;
  border: 0;
  background: #fff;
}
.AdminNewsPage__create.show {
  height: auto;
  padding: 3rem 0;
  border-top: solid 0.1rem #e9eaee;
  border-bottom: solid 0.1rem #e9eaee;
  margin-bottom: 6rem;
}
.AdminNewsPage__create .CheckBoxWithLabel.row {
  gap: 2rem;
}
.AdminNewsPage__create .CheckBoxWithLabel.row .form-check.col {
  padding: 0;
  display: flex;
  gap: 1rem;
}
.AdminNewsPage__create .CheckBoxWithLabel.row .form-check-label {
  margin: 0;
}
.AdminNewsPage__create .CheckBoxWithLabel.row .form-check-input {
  margin-left: 0;
}
.AdminNewsPage small {
  color: #cd202f;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  display: block;
}
.AdminNewsPage .label {
  font-size: 1.6rem;
}
.AdminNewsPage .label.en {
  color: #cd202f;
}
.AdminNewsPage .form-check-label {
  font-size: 1.4rem;
}
.AdminNewsPage .form-control {
  margin-bottom: 1rem !important;
}
.AdminNewsPage .card-title {
  font-family: "TripletaBold";
  font-size: 1.2rem;
  color: rgba(5, 90, 165, 0.6);
  padding-left: 1rem;
  margin-bottom: 0;
}
.AdminNewsPage .card-footer {
  padding: 0;
  background: transparent;
}
.AdminNewsPage .card-footer .leti-btn {
  width: 100%;
  border: 0;
  font-size: 1.6rem;
  padding: 1rem;
  background: #055aa5;
  color: #fff;
}
.AdminNewsPage .card-footer .leti-btn:hover {
  background: rgba(5, 90, 165, 0.8);
}
.AdminNewsPage .card-body {
  position: relative;
}
.AdminNewsPage .card-body-img {
  overflow: hidden;
  padding: 0;
}
.AdminNewsPage .card-body-img img {
  width: 100%;
  height: 24rem;
  object-fit: cover;
  border-radius: 0.4rem 0.4rem 0 0;
}
.AdminNewsPage .alert-danger {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  height: auto;
  margin-top: -3rem;
  z-index: 1;
  font-size: 1.6rem;
  text-align: center;
  margin-left: -25%;
}
.AdminNewsPage .card-text {
  font-weight: 600;
  color: #000;
  font-size: 1.8rem;
}