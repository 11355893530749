.BottomCta__title {
  color: #fff;
  position: absolute;
  display: block;
  width: 46%;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-left: -23%;
  margin-top: -5rem;
  z-index: 2;
}
.BottomCta__title .leti-btn {
  background: transparent;
  color: #fff;
  border-color: #fff;
  margin-top: 1rem;
  opacity: 0.5;
}
.BottomCta__img {
  overflow: hidden;
  position: relative;
}
.BottomCta__img:hover .leti-btn {
  opacity: 1;
  border-width: 0.3rem;
}
.BottomCta__img:hover .BottomCta__proposito,
.BottomCta__img:hover .BottomCta__novedades {
  transform: scale(1.2);
}
.BottomCta__img:hover .BottomCta__proposito:before,
.BottomCta__img:hover .BottomCta__novedades:before {
  opacity: 1;
  background: rgba(5, 90, 165, 0.8);
}
.BottomCta__proposito, .BottomCta__novedades {
  width: auto;
  height: 50rem;
  transition: all 0.2s ease-in-out;
}
.BottomCta__proposito:before, .BottomCta__novedades:before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(5, 90, 165, 0.4);
  transition: all 0.2s ease-in-out;
  opacity: 1;
}
.BottomCta__proposito {
  background: url(../../../../images/iyd-equipo.jpg) no-repeat center/cover;
}
.BottomCta__novedades {
  background: url(../../../../images/iyd-novedades.jpg) no-repeat center center/cover;
}

@media (max-width: 576px) {
  .BottomCta__title {
    width: 60%;
    margin-left: -30%;
  }
}
@media (max-width: 576px) {
  .BottomCta__title {
    width: 86%;
    margin-left: -44%;
  }
  .BottomCta__title h2 {
    font-size: 2.2rem;
  }
  .BottomCta__title .leti-btn {
    opacity: 1;
  }
  .BottomCta__proposito, .BottomCta__novedades {
    height: 30rem;
  }
}