.BiocontrolledInfo-Modified {
  background-color: #f8f8f8;
  padding: 6rem 0;
  margin-top: 3rem;
}
.BiocontrolledInfo-Modified--wrapper {
  position: relative;
  height: 80rem;
}
.BiocontrolledInfo-Modified--image {
  background: url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fpills.png?alt=media&token=b29f3c16-3b5b-40b1-b378-0fbddeaffe7f) no-repeat center/cover;
  width: 32rem;
  height: 60rem;
  position: absolute;
  top: 50%;
  margin-top: -30rem;
  left: 50%;
  margin-left: -16rem;
}
.BiocontrolledInfo-Modified h1 {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 12rem;
  color: #1a1a1a;
  position: absolute;
}
.BiocontrolledInfo-Modified h1:nth-child(1) {
  top: 0;
  left: 0;
}
.BiocontrolledInfo-Modified h1:nth-child(2) {
  bottom: 2rem;
  right: 0;
}
.BiocontrolledInfo-Modified h1:nth-child(3) {
  bottom: -8rem;
  right: 0;
}
.BiocontrolledInfo-Modified p {
  position: absolute;
  color: #737373;
  font-size: 2rem;
  line-height: 1.5;
  width: 26rem;
  top: 10rem;
  right: 2rem;
}
.BiocontrolledInfo-Modified p b,
.BiocontrolledInfo-Modified p strong {
  font-weight: 400;
}
.BiocontrolledInfo-Modified .bold p {
  position: absolute;
  color: #1a1a1a;
  font-size: 2rem;
  line-height: 1.5;
  width: 26rem;
  bottom: 18rem;
  left: 0;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Modified {
    padding: 3rem;
    margin-top: 0;
  }
  .BiocontrolledInfo-Modified--wrapper {
    height: auto;
  }
  .BiocontrolledInfo-Modified--image {
    width: 40%;
    height: 24rem;
    position: absolute;
    margin-top: 0;
    left: 50%;
    margin-left: 28%;
    filter: none;
    top: 0;
    left: 0;
  }
  .BiocontrolledInfo-Modified h1 {
    font-size: 4.8rem;
    position: relative;
  }
  .BiocontrolledInfo-Modified h1:nth-child(1) {
    top: 0;
    left: 0;
  }
  .BiocontrolledInfo-Modified h1:nth-child(2) {
    bottom: 0;
    right: 0;
    text-align: right;
  }
  .BiocontrolledInfo-Modified h1:nth-child(3) {
    bottom: 0;
    right: 0;
    margin-bottom: 4rem;
  }
  .BiocontrolledInfo-Modified p {
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    top: 0;
    right: 0;
  }
  .BiocontrolledInfo-Modified .bold p {
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}