.edit-custom {
  max-height: 7rem !important;
  object-fit: contain !important;
}

.custom-before {
  margin: 4rem 0;
}
.custom-before:before {
  right: 7rem;
}
.custom-before h3 {
  text-align: center;
  margin-top: 1rem;
}
.custom-before p {
  text-align: center;
  margin: 0;
}