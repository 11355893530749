.Banner__Products {
  position: relative;
}
.Banner__Products .leti-btn {
  margin-right: 2rem;
  margin-top: 3rem;
}
.Banner__Products .leti-btn:last-child {
  margin-right: 0;
}
.Banner__Products__btns {
  position: absolute;
  bottom: 4rem;
}
.Banner__Products-logo {
  margin-top: 6rem;
  display: block;
}
.Banner__Products__title {
  height: 70rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.Banner__Products__title strong {
  color: #055aa5;
  font-weight: unset;
}
.Banner__Products__title h3 {
  font-size: 3rem;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  margin: 1rem auto;
}
.Banner__Products__bg {
  position: absolute;
  width: 54rem;
  height: 54rem;
  right: -6rem;
  top: -10rem;
  transform: rotate(0deg);
}
.Banner__Products__blue {
  position: absolute;
  width: 44rem;
  height: 44rem;
  background: url(../../../../images/leti-blue-banner.png) no-repeat center/contain;
  right: -6rem;
  top: 12rem;
  transform: rotate(0deg);
}

@media (max-width: 1180px) {
  .Banner__Products__title h1 {
    font-size: 5rem;
  }
  .Banner__Products__title h3 {
    font-size: 2.4rem;
  }
  .Banner__Products__btns .leti-btn {
    width: 28%;
  }
}
@media (max-width: 576px) {
  .Banner__Products__btns {
    position: static;
  }
  .Banner__Products__btns__align {
    justify-content: center;
  }
  .Banner__Products__btns .leti-btn {
    width: 100%;
  }
  .Banner__Products-logo {
    width: 28rem;
    margin-top: 5rem;
  }
  .Banner__Products__title {
    height: 85rem;
    justify-content: flex-start;
    margin-top: 6rem;
  }
  .Banner__Products__title h1 {
    font-size: 3.5rem;
  }
  .Banner__Products__title h3 {
    font-size: 2rem;
    margin: 0rem auto 8rem auto;
    line-height: 2.8rem;
  }
  .Banner__Products__bg {
    width: 38rem;
    height: 38rem;
    right: -6rem;
    top: 40rem;
  }
  .Banner__Products__blue {
    width: 30rem;
    height: 30rem;
    right: -6rem;
    top: 56rem;
  }
}