.Timeline__leti {
  position: relative;
  padding: 0;
  background: #f5f5f5;
  margin-top: 8rem;
}
.Timeline__leti__image {
  height: 65rem;
}
.Timeline__leti__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f5f5f5 url(../../../../images/timeline-bg.svg) no-repeat right center/contain;
  position: relative;
}
.Timeline__leti__info:before {
  content: "";
  width: 100%;
  height: 22rem;
  top: 0rem;
  background: #fff;
  position: absolute;
  right: 0;
  z-index: 0;
}
.Timeline__leti__info .leti-btn {
  margin-left: 5rem;
  margin-bottom: 3rem;
}
.Timeline__leti__desc {
  padding: 2rem 0 2rem 6rem;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 0rem;
}
.Timeline__leti__desc strong {
  color: #055aa5;
  font-weight: unset;
}
.Timeline__leti .slick-slider {
  position: relative;
  display: block;
}
.Timeline__leti .slick-slider .slick-next,
.Timeline__leti .slick-slider .slick-prev {
  width: 4rem;
  height: 4rem;
  z-index: 4;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
  position: absolute;
}
.Timeline__leti .slick-slider .slick-next:hover,
.Timeline__leti .slick-slider .slick-prev:hover {
  opacity: 1;
}
.Timeline__leti .slick-slider .slick-next:before,
.Timeline__leti .slick-slider .slick-prev:before {
  content: "";
}
.Timeline__leti .slick-slider .slick-next {
  background: url(../../../../images/next-arrow.svg) no-repeat center center/contain;
}
.Timeline__leti .slick-slider .slick-prev {
  background: url(../../../../images/prev-arrow.svg) no-repeat center center/contain;
}

@media (max-width: 576px) {
  .Timeline__leti__image {
    height: 38rem;
  }
  .Timeline__leti__desc {
    padding: 2rem 4rem 0 4rem;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 6rem;
  }
  .Timeline__leti .slick-next {
    transform: translate(-25rem, 1rem) !important;
  }
  .Timeline__leti .slick-prev {
    transform: translate(5rem, 1rem) !important;
  }
  .Timeline__leti__info .leti-btn {
    margin: 3rem 0;
  }
  .Timeline__leti__info:before {
    content: unset;
  }
  .Timeline__leti__btn {
    justify-content: center;
  }
}