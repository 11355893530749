.NewsPage__leti {
  margin-top: 12rem;
}
.NewsPage__leti h1 {
  margin: 10rem 0 6rem 0;
}
.NewsPage__leti__logo {
  width: 31rem;
  margin-bottom: 3rem;
}
.NewsPage__leti-hero {
  position: absolute;
  right: 0;
  padding: 0;
}
.NewsPage__leti-hero .card-body,
.NewsPage__leti-hero .card-footer {
  padding-left: 30%;
}
.NewsPage__leti__card {
  max-height: 50rem;
}
.NewsPage__leti__card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.NewsPage__leti-resume .card {
  border-bottom: solid 0.1rem rgba(74, 74, 74, 0.1);
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}
.NewsPage__leti-resume .card:last-child {
  border-bottom: none;
}

@media (max-width: 576px) {
  .NewsPage__leti {
    margin: 0;
  }
  .NewsPage__leti .container {
    padding: 0;
  }
  .NewsPage__leti h1 {
    margin: 3rem 2rem;
  }
  .NewsPage__leti__logo {
    width: 20rem;
    margin-bottom: 3rem;
  }
  .NewsPage__leti__card {
    display: none;
  }
  .NewsPage__leti-hero {
    border-bottom: solid 0.1rem rgba(74, 74, 74, 0.1);
  }
  .NewsPage__leti-hero .card-body,
  .NewsPage__leti-hero .card-footer {
    padding: 0 2rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .NewsPage__leti-resume {
    padding: 6rem 0 2rem 2rem;
  }
  .NewsPage__leti-resume .card {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .NewsPage__leti-resume .card:last-child {
    border-bottom: solid 0.1rem rgba(74, 74, 74, 0.1);
    margin-bottom: 3rem;
  }
  .NewsPage__lastest {
    padding-top: 0;
  }
}