.ProductBottom {
  padding: 10rem 0;
  position: relative;
  background: #f5f5f5;
  margin: 20rem 0 6rem 0;
}
.ProductBottom__desc {
  margin-bottom: 3rem;
}
.ProductBottom__clip {
  position: absolute;
  top: 50%;
  margin-top: -30rem;
  left: -30rem;
  height: 90rem;
  width: 90rem;
  mask-image: url(../../../../images/mask.svg);
  mask-size: 90rem;
  mask-repeat: no-repeat;
  mask-position: top left;
}
.ProductBottom__blue-stroke {
  background: url(../../../../images/triangulo-rojo-stroke.svg) no-repeat center center/contain;
  position: absolute;
  width: 60rem;
  height: 60rem;
  z-index: -1;
  top: -15rem;
  left: -10rem;
}

@media (max-width: 576px) {
  .ProductBottom {
    padding-top: 30rem;
    margin-top: 5rem;
    padding-bottom: 6rem;
    margin-bottom: 0;
  }
  .ProductBottom .leti-btn {
    margin-top: 3rem;
  }
  .ProductBottom .row {
    justify-content: center;
  }
  .ProductBottom__clip {
    position: absolute;
    background-position: 20rem 10rem !important;
    background-size: contain !important;
    mask-position: center 5rem !important;
    height: 45rem;
    mask-size: 40rem;
    top: -11rem;
  }
  .ProductBottom__blue-stroke {
    position: absolute;
    width: 30rem;
    height: 30rem;
    z-index: -1;
    top: -38rem;
    left: unset;
  }
  .ProductBottom__info {
    font-size: 1.6rem;
  }
  .ProductBottom__info h1 {
    margin-top: 3rem;
  }
}