.InfoBanner {
  position: relative;
  overflow: hidden;
  height: 70rem;
  padding: 0;
}
.InfoBanner-data {
  padding: 4rem 0;
  background: rgba(255, 255, 255, 0.86);
  position: absolute;
  bottom: 0;
  left: 0;
}
.InfoBanner-colaboradores {
  color: #8c8c8c;
  font-size: 10rem;
  font-weight: 600;
  border-bottom: solid 1px #000;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  position: relative;
  padding-left: 13rem;
  padding-top: 2rem;
}
.InfoBanner-colaboradores-texto {
  color: #000;
  font-weight: 600;
  display: block;
  font-size: 3.4rem;
  margin-top: 4rem;
}
.InfoBanner-colaboradores:before {
  content: "";
  width: 10rem;
  height: 10rem;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.aside {
  padding-top: 4rem;
}
.aside .custom-margin {
  margin-top: 5rem;
}
.aside .custom-margin,
.aside .text {
  font-size: 7rem;
  color: #8c8c8c;
}
.aside .InfoBanner-colaboradores {
  border-bottom: 0;
}
.aside .InfoBanner-colaboradores-mujeres, .aside .InfoBanner-colaboradores-hombres {
  padding: 0 0 0 7rem;
  font-size: 7rem;
}
.aside .InfoBanner-colaboradores-mujeres:before, .aside .InfoBanner-colaboradores-hombres:before {
  width: 7rem;
  height: 7rem;
  top: 2rem;
}
.aside .InfoBanner-colaboradores-texto {
  margin-top: 2rem;
  padding-left: 7.3rem;
}

@media (max-width: 576px) {
  .InfoBanner .text-desc {
    padding-left: 2rem;
  }
  .InfoBanner-data {
    padding: 2rem 0;
  }
  .InfoBanner-colaboradores {
    font-size: 6rem;
  }
  .InfoBanner-colaboradores-texto {
    font-size: 2.2rem;
    margin-top: 2rem;
  }
  .InfoBanner-colaboradores:before {
    height: 6rem;
  }
  .InfoBanner__title {
    height: 70rem;
    align-items: flex-start;
  }
  .InfoBanner__title h1 {
    margin-top: 3rem;
  }
  .aside .custom-margin,
  .aside .text {
    font-size: 3rem;
    margin-top: 0;
  }
  .aside .InfoBanner-colaboradores-mujeres, .aside .InfoBanner-colaboradores-hombres {
    font-size: 5rem;
  }
  .aside .InfoBanner-colaboradores-mujeres:before, .aside .InfoBanner-colaboradores-hombres:before {
    width: 7rem;
    height: 7rem;
    top: 2rem;
  }
  .aside .InfoBanner-colaboradores-texto {
    margin-top: 0rem;
    padding-left: 7.3rem;
  }
}