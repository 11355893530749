.InfoCards {
  min-height: 35rem;
}
.InfoCards__Leti__cards {
  background: #f5f5f5;
  padding: 4rem;
  border: solid 1rem #fff;
}
.InfoCards__Leti__cards h4 {
  font-family: "TripletaBold";
  font-size: 2.4rem;
  color: #4a4a4a;
}
.InfoCards__Leti__cards p {
  margin-top: 3rem;
  font-size: 1.8rem;
  line-height: 3rem;
}
.InfoCards__Leti__cards p strong {
  color: #055aa5;
  font-weight: unset;
}

@media (max-width: 576px) {
  .InfoCards__Leti__cards {
    padding: 2rem;
    margin: 1rem 2rem;
    width: 91.6666%;
  }
  .InfoCards__Leti__cards h4 {
    font-size: 2rem;
  }
  .InfoCards__Leti__cards p {
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }
}