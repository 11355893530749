.SwitchWithLabel {
  margin: 2rem 0 !important;
}
.SwitchWithLabel .form-check {
  padding-right: 0.8rem;
}
.SwitchWithLabel .form-check-input {
  transform: scale(0.8);
}
.SwitchWithLabel .form-check-input:focus {
  box-shadow: none;
  border-color: #055aa5;
}
.SwitchWithLabel .form-check-input:checked {
  background-color: #055aa5;
  border-color: #055aa5;
}
.SwitchWithLabel .form-check-input:hover {
  cursor: pointer;
}
.SwitchWithLabel .form-check-label {
  color: #737b7f;
  font-size: 1.6rem;
  margin-left: -1rem;
}
.SwitchWithLabel .form-check-label:hover {
  cursor: pointer;
}