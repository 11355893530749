.FindProduct {
  background: url(../../../../images/find-product-bg.png) no-repeat left center;
  min-height: 42rem;
  display: flex;
  align-items: center;
}
.FindProduct.no-back {
  margin-top: 10rem;
  background: none;
  background: url(../../../../images/our-people-right.svg) no-repeat center right/auto 40rem, url(../../../../images/our-people-left.svg) no-repeat center left/auto 40rem;
}
.FindProduct form {
  padding: 0;
}
.FindProduct__row {
  justify-content: center;
}
.FindProduct__row--custom {
  margin-top: 10rem;
}
.FindProduct__row--custom .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 0.1rem #e9eaee;
  border-radius: 2rem;
  padding: 2rem 0;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.2);
  margin-top: 4rem;
  width: 22%;
  max-height: 112px;
}
.FindProduct__row--custom .logo img {
  max-width: 20rem;
  transition: all 0.2s ease-in-out;
}
.FindProduct__row--custom .logo.locatel {
  background-color: #01962d;
}
.FindProduct__row--custom .logo.farmatodo {
  background-color: #202d57;
}
.FindProduct__row--custom .logo.nuevosiglo img {
  height: 9rem;
}
.FindProduct__row--custom .logo:hover img {
  transform: scale(1.1);
}
.FindProduct__form {
  display: flex;
  align-items: center;
  height: 7.6rem;
}
.FindProduct__form .DropdownWithLabel .form-control.category {
  background: url(../../../../images/dropdown-icon.svg) no-repeat 2rem center;
  padding-left: 6rem;
  height: 7.6rem;
  border-radius: 0;
  margin: 0;
  border: none;
  border-top: solid 0.1rem #737b7f;
  border-bottom: solid 0.1rem #737b7f;
}
.FindProduct__form .DropdownWithLabel .form-control.product {
  padding-left: 2rem;
  border-left: solid 0.1rem #737b7f;
  background: url(../../../../images/inputwithlabel.svg) no-repeat right center;
  border-right: none;
  height: 7.6rem;
}
.FindProduct__form .form-group {
  margin: 0 !important;
}
.FindProduct__form .leti-btn {
  background: #fff url(../../../../images/search.svg) no-repeat center/4rem;
  height: 7.6rem;
  transition: all 0.1s ease-in-out;
}
.FindProduct__form .leti-btn:hover {
  border-width: 0.3rem;
}

@media (max-width: 576px) {
  .FindProduct {
    background: url(../../../../images/find-product-bg.png) no-repeat left 4rem/30%;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 52rem;
  }
  .FindProduct h1.FindProduct__custom__title {
    margin: 0rem 0 5rem 2rem !important;
  }
  .FindProduct.no-back {
    background: url(../../../../images/our-people-right.svg) no-repeat center right/auto 40rem;
    margin-top: 5rem;
  }
  .FindProduct__form .input-group {
    border: solid 0.1rem #737b7f;
    border-bottom: 0;
  }
  .FindProduct__form .DropdownWithLabel .form-control.category {
    padding-left: 6rem;
    height: 6.2rem;
    border: 0;
  }
  .FindProduct__form .form-group .form-control {
    margin-bottom: 0;
  }
  .FindProduct__form .form-group .form-control.category.InputWithLabel {
    background: #fff url(../../../../images/dropdown-resp-bottom.svg) no-repeat center bottom;
    border: 0;
  }
  .FindProduct__form .form-group .form-control.product {
    background: #fff;
    height: 6rem;
    border: 0;
    border-bottom: solid 0.1rem #737b7f;
    border-radius: 0;
  }
  .FindProduct__form .leti-btn {
    background: #fff;
    height: 6.2rem;
    width: 100.5%;
  }
  .FindProduct__form .leti-btn:before {
    content: "Buscar el producto";
    font-size: 1.8rem;
  }
  .FindProduct__form .leti-btn:hover {
    background: blue;
  }
  .FindProduct__row--custom {
    margin-bottom: 10rem;
  }
  .FindProduct__row--custom h1 {
    margin: 0 !important;
  }
  .FindProduct__row--custom .logo {
    width: 100%;
    margin-top: 2rem;
  }
}