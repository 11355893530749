@charset "UTF-8";
.BiocontrolledInfo-Marquee .marquee {
  overflow: hidden; /* Oculta lo que sale del contenedor */
  position: relative;
}
.BiocontrolledInfo-Marquee .marquee .marquee__content {
  display: flex;
  animation: marquee 20s linear infinite; /* Animación infinita */
}
.BiocontrolledInfo-Marquee .marquee p {
  font-size: 4.5rem;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 100;
  margin: 0;
  padding: 1rem 0;
  padding-right: 2rem; /* Espaciado entre textos */
  display: inline-block;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Marquee .marquee p {
    font-size: 2rem;
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}