.Productos {
  padding: 20rem 0 10rem 0;
  position: relative;
}
.Productos [class*=__genven] {
  position: absolute;
}
.Productos__genven-001 {
  width: 37rem;
  max-width: 37rem;
  height: 42rem;
  top: 12rem;
  right: 3rem;
  transform: rotate(0deg);
}
.Productos__genven-002 {
  width: 43rem;
  max-width: 43rem;
  height: 23.5rem;
  top: 54rem;
  right: -15rem;
  transform: rotate(0deg);
  z-index: 1;
}
.Productos__genven-003 {
  width: 26rem;
  max-width: 43rem;
  height: 37.5rem;
  top: -6rem;
  right: -20rem;
  transform: rotate(0deg);
  z-index: -1;
}
.Productos__desc {
  margin-bottom: 3rem;
  font-size: 3rem;
  text-align: right;
}
.Productos__desc strong {
  color: #055aa5;
}
.Productos .leti-btn {
  margin-top: 3rem;
  float: right;
}

@media (max-width: 576px) {
  .Productos {
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    height: 86rem;
  }
  .Productos__genven-001 {
    top: 350px;
    right: -100px;
    transform: scale(0.7) !important;
  }
  .Productos__genven-003 {
    width: 40rem;
    top: 57rem;
    z-index: 2;
    right: inherit;
  }
  .Productos .row {
    justify-content: center;
    background: #fff;
    padding-bottom: 3rem;
    padding-top: 6rem;
  }
  .Productos__info {
    font-size: 1.6rem;
  }
  .Productos .leti-btn {
    margin-top: 0rem;
  }
}