.EditContent-timeline {
  padding-top: 6rem;
}
.EditContent-timeline img {
  width: 100%;
  height: auto;
  max-height: 24rem;
  position: relative;
  object-fit: cover;
}
.EditContent-timeline p {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #737b7f;
}

.EditCarousel__edit {
  position: relative;
}
.EditCarousel__edit:before {
  content: "";
  width: 3rem;
  height: 3rem;
  display: block;
  background: url(../../../../../images/edit.svg) no-repeat center center/2rem;
  position: absolute;
  right: -3rem;
  top: -2rem;
}
.EditCarousel__edit:hover {
  cursor: pointer;
  opacity: 0.8;
}
.EditCarousel__edit.logros {
  border: solid 0.1rem #e9eaee;
  margin: 0 0.5rem 2rem 0.5rem;
  background: transparent;
}
.EditCarousel__edit.logros:before {
  right: 1rem;
  top: -2rem;
  background: #fff url(../../../../../images/edit.svg) no-repeat center center/2rem;
  z-index: 1;
}
.EditCarousel__edit.logros img {
  margin-top: 2rem;
}
.EditCarousel__edit.logros h4 {
  font-family: "TripletaBold";
}
.EditCarousel__edit-force:before {
  right: 0rem;
  z-index: 1;
}

@media (max-width: 576px) {
  .EditContent-timeline {
    padding-top: 2rem;
  }
  .EditContent-timeline img {
    object-fit: contain;
    margin: 2rem auto;
    display: block;
  }
  .EditContent-timeline p {
    text-align: center;
  }
  .EditCarousel__edit img {
    width: 70%;
    height: 4rem;
  }
  .EditCarousel__edit:before {
    right: 0rem;
    top: 1rem;
  }
}