@charset "UTF-8";
.BiocontrolledInfo-Footer {
  padding: 0;
  max-width: 100%; /* Para que no exceda el ancho de la pantalla */
  height: 84vh; /* Ajusta el alto que prefieras */
  overflow: hidden; /* Esconde el exceso si el video es más alto */
  position: relative;
}
.BiocontrolledInfo-Footer--wrapper {
  position: relative;
  height: 72rem;
}
.BiocontrolledInfo-Footer video {
  width: 100%; /* Asegura que el video se escale en ancho */
  height: 100%; /* Mantiene el alto del video dentro del contenedor */
  object-fit: cover; /* Asegura que el video llene el contenedor sin distorsión */
}
.BiocontrolledInfo-Footer--info {
  position: absolute;
  top: 4rem;
  right: 4rem;
  justify-content: flex-end;
}
.BiocontrolledInfo-Footer--info .row .col-6:last-child .card {
  margin-bottom: 1rem;
}
.BiocontrolledInfo-Footer--info .row .col-6:last-child .card:last-child {
  margin-bottom: 0;
}
.BiocontrolledInfo-Footer--info .card {
  border: 0;
  background-color: transparent;
}
.BiocontrolledInfo-Footer--info .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4rem;
  background-color: white;
  padding: 3rem;
  flex-direction: column;
}
.BiocontrolledInfo-Footer--info .card-title {
  color: #1d5ba8;
  font-weight: 100;
  font-size: 3rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-align: center;
  display: block;
}
.BiocontrolledInfo-Footer--info .card p {
  font-size: 2.4rem;
  color: #737373;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Footer {
    height: 80vh;
  }
  .BiocontrolledInfo-Footer--wrapper {
    height: 53rem;
  }
  .BiocontrolledInfo-Footer--info {
    top: 2rem;
    right: 2rem;
  }
  .BiocontrolledInfo-Footer--info .card {
    margin: 0 1rem;
    margin-bottom: 1rem;
  }
  .BiocontrolledInfo-Footer--info .card-body {
    padding: 2rem;
  }
  .BiocontrolledInfo-Footer--info .card-title {
    font-size: 2.4rem;
  }
  .BiocontrolledInfo-Footer--info .card p {
    font-size: 1.6rem;
  }
}