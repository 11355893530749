.InfoCards__OurPhilosophy__cards {
  background: #f5f5f5;
  padding: 4rem;
  border: solid 1rem #fff;
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.InfoCards__OurPhilosophy__cards:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #abadb2;
  mix-blend-mode: multiply;
}
.InfoCards__OurPhilosophy__cards h4 {
  font-family: "TripletaBold";
  font-size: 2.4rem;
  color: #fff;
  position: absolute;
}
.InfoCards__OurPhilosophy__cards p {
  margin-top: 3rem;
  font-size: 1.8rem;
  line-height: 3rem;
}

@media (max-width: 576px) {
  .InfoCards__OurPhilosophy {
    height: 47rem;
    margin-top: 6rem;
    padding: 0;
  }
  .InfoCards__OurPhilosophy .CompaniesInfoResp__card {
    background: none;
  }
  .InfoCards__OurPhilosophy__cards {
    padding: 0;
    margin: 0;
    height: 40rem;
    width: 100%;
    border: 0;
  }
  .InfoCards__OurPhilosophy__cards:before {
    display: none;
  }
  .InfoCards__OurPhilosophy__cards p {
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }
}