.BiocontrolledInfo-TechTitle {
  margin-top: 10.6rem;
  margin-bottom: 10.6rem;
}
.BiocontrolledInfo-TechTitle h1 {
  font-size: 6.4rem;
  color: #1A1A1A;
  font-weight: 600;
  text-transform: uppercase;
}
.BiocontrolledInfo-TechTitle p {
  font-size: 2.8rem;
  color: #737373;
  line-height: 1.5;
  font-weight: 300;
}

@media (max-width: 768px) {
  .BiocontrolledInfo-TechTitle {
    padding: 0 2rem;
    margin: 2.4rem auto;
  }
  .BiocontrolledInfo-TechTitle h1 {
    font-size: 3.2rem;
    font-weight: 900;
  }
  .BiocontrolledInfo-TechTitle p {
    font-size: 1.6rem;
  }
}