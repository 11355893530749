.NotFoundPage {
  font-size: 2.4rem;
}
.NotFoundPage__icon {
  height: 28rem;
  background: url(../../../images/404-icon.svg) no-repeat center/contain;
}
.NotFoundPage .leti-btn {
  margin: 4rem auto 4rem auto;
}

@media (max-width: 576px) {
  .NotFoundPage__icon {
    margin-top: 4rem;
    height: 14rem;
  }
}