.Unidades {
  margin: 20rem auto 10rem auto;
  position: relative;
}
.Unidades .big-gray-triangle,
.Unidades .small-gray-triangle {
  position: absolute;
  opacity: 0.4;
  z-index: -1;
}
.Unidades .big-gray-triangle {
  background: url(../../../../images/big-grey.svg) no-repeat center center/contain;
  width: 70rem;
  height: 70rem;
  right: -10rem;
  top: -15rem;
}
.Unidades .small-gray-triangle {
  background: url(../../../../images/small-grey.svg) no-repeat center center/contain;
  width: 50rem;
  height: 55rem;
  right: 27rem;
}
.Unidades__row {
  margin: 8rem 0;
}
.Unidades__logo {
  min-height: 40rem;
  background-size: 30rem 10rem !important;
}
.Unidades__desc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 3rem;
  flex-direction: column;
}
.Unidades__desc__right {
  text-align: right;
  align-items: flex-end;
}
.Unidades__square-btn {
  background: url(../../../../images/plus.svg) no-repeat center center/contain;
  width: 6.5rem;
  height: 6.5rem;
  border: solid 0.15rem #055aa5;
  background-size: 3rem;
  margin-top: 3rem;
  transition: all 200ms ease-in-out;
}
.Unidades__square-btn:hover {
  background: #055aa5 url(../../../../images/plus-white.svg) no-repeat center center/contain;
  background-size: 3rem;
}
.Unidades__logos [class*=css-]:nth-child(3) .Unidades__logo {
  background-size: 45rem 30rem !important;
}

@media (max-width: 576px) {
  .Unidades {
    margin: 0;
  }
  .Unidades h1 {
    margin-left: 2rem;
  }
  .Unidades .big-gray-triangle,
  .Unidades .small-gray-triangle {
    display: none;
  }
  .Unidades__row {
    margin: 4rem 0;
  }
  .Unidades__desc {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
  .Unidades__logo {
    min-height: 18rem;
    background-size: 15rem !important;
  }
  .Unidades__logos [class*=css-]:nth-child(1) .Unidades__logo {
    background-size: 16rem auto !important;
  }
  .Unidades__logos [class*=css-]:nth-child(3) .Unidades__logo {
    background-size: 24rem 10rem !important;
  }
}