.Loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.Loader__container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}
.Loader__container__content {
  background: #fff;
  padding: 2rem 4rem;
  font-size: 1.6rem;
}
.Loader__stroke__red {
  background: url(../../images/triangulo-rojo-stroke.svg) no-repeat center/contain;
  width: 30rem;
  height: 30rem;
  transform: rotate(0deg);
  margin-top: -15rem;
  margin-left: -15rem;
  position: absolute;
  display: block;
  animation: rotate 5s ease infinite;
  top: 50%;
  left: 50%;
}
.Loader__stroke__blue {
  background: url(../../images/triangulo-azul-stroke.svg) no-repeat center/contain;
  width: 20rem;
  height: 20rem;
  transform: rotate(0deg);
  margin-top: -10rem;
  margin-left: -10rem;
  position: absolute;
  display: block;
  animation: rotate 2s ease infinite;
  animation-direction: reverse;
  top: 50%;
  left: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}