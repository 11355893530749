.EditInfoBannerOurPeople__images {
  max-height: 8rem !important;
  width: auto !important;
}
.EditInfoBannerOurPeople .custom__edit {
  text-align: center;
}
.EditInfoBannerOurPeople .custom__edit:before {
  right: -2rem;
}
.EditInfoBannerOurPeople .custom__edit h3 {
  text-align: center;
  margin-top: 1rem;
}
.EditInfoBannerOurPeople .custom__edit p {
  text-align: center;
  margin: 0;
}