.EditProductLines-delete-tags {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}
.EditProductLines-delete-tags:nth-child(n+6) {
  margin-bottom: 0;
}
.EditProductLines-delete-tags:before {
  content: "x";
  font-size: 1.6rem;
  color: #cd202f;
  margin-right: 0.5rem;
}
.EditProductLines-delete-tags:hover {
  cursor: pointer;
  color: #cd202f;
}