.LandingInfoContent {
  height: 130rem;
  display: flex;
  align-items: center;
  margin-top: -27rem;
}
.LandingInfoContent--wrapper {
  text-align: center;
  color: white;
}
.LandingInfoContent--wrapper h2 {
  color: #b2dbff;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
}
.LandingInfoContent--wrapper h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 4rem;
  font-weight: 400;
  margin-bottom: 5.6rem;
}
.LandingInfoContent--wrapper .icon {
  display: block;
  width: 6rem;
  height: 6rem;
  margin-bottom: 1rem;
}
.LandingInfoContent--card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  padding: 0 3rem;
}
.LandingInfoContent--card b,
.LandingInfoContent--card strong {
  font-size: 1.3rem;
}

@media (max-width: 48rem) {
  .LandingInfoContent {
    background: none !important;
    background-color: #0e95f2 !important;
    margin-top: 0;
  }
  .LandingInfoContent--wrapper h2 {
    font-size: 1.6rem;
    padding: 0 7rem;
  }
  .LandingInfoContent--wrapper h1 {
    font-size: 3.2rem;
  }
  .LandingInfoContent--cards {
    gap: 7rem;
  }
}