.LandingBanner {
  height: 42rem;
  padding: 0 6rem;
}
.LandingBanner--main {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  height: 42rem;
}
.LandingBanner--main .custom {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  margin-top: -10.6rem;
  z-index: 1;
}
.LandingBanner--main .custom h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 5.2rem;
  font-weight: 700;
  color: #1095f2;
  line-height: 5.2rem;
  margin-bottom: 4rem;
  padding-right: 6rem;
}
.LandingBanner--main .custom span {
  font-family: "Work Sans", sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  color: white;
  line-height: 5.2rem;
  background-color: #b9d73d;
  border-radius: 100px;
  padding: 0.8rem 2.4rem;
}
.LandingBanner--main .custom--absolute {
  position: absolute;
  right: 0;
}
.LandingBanner img {
  max-width: 100%;
  width: 100%;
}
.LandingBanner .flex-wraper {
  display: flex;
  gap: 2rem;
}
.LandingBanner .flex-wraper .item {
  flex: 1;
  transition: all 0.4s ease-in;
  border-radius: 1rem;
}
.LandingBanner .flex-wraper .item .card-wrapper figure {
  height: 41rem;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2rem;
}
.LandingBanner .flex-wraper .item .card-wrapper figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.LandingBanner .flex-wraper .item:hover {
  flex: 7;
}

@media (max-width: 48rem) {
  .LandingBanner {
    padding: 0 1.6rem;
    height: 60rem;
  }
  .LandingBanner--main {
    height: 60rem;
  }
  .LandingBanner--main .custom {
    padding: 3.6rem;
    margin-top: 0;
    height: 60rem;
    top: 0;
    justify-content: flex-start;
  }
  .LandingBanner--main .custom h1 {
    font-size: 4rem;
    color: white;
    line-height: 4rem;
    padding-right: 0;
    top: 0;
  }
  .LandingBanner--main .custom span {
    font-family: "Work Sans", sans-serif;
    font-size: 2.4rem;
    line-height: 4.5rem;
    position: absolute;
    bottom: -2.8rem;
    left: 50%;
    margin-left: -13.5rem;
  }
  .LandingBanner .flex-wraper .item:not(:first-child) {
    display: none;
  }
  .LandingBanner .flex-wraper .item .card-wrapper figure {
    height: 60rem;
  }
}