.LandingNews {
  padding: 0 6rem;
  margin: 11rem auto 8rem auto;
}
.LandingNews--image, .LandingNews--card, .LandingNews--info {
  border-radius: 2.5rem;
  transition: all 0.2s ease-in-out;
  height: 26.4rem;
  border: solid 1rem white;
  padding: 2rem;
  text-decoration: none;
}
.LandingNews--info h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 5.2rem;
  font-weight: 500;
  color: #1095f2;
}
.LandingNews--info h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #808080;
}
.LandingNews--card {
  background-color: #f2f2f2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: #808080;
}
.LandingNews--card--content--link {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #b2b2b2;
  float: right;
  text-transform: uppercase;
  text-decoration: underline;
}
.LandingNews--card:hover {
  background-color: #1095f2;
}
.LandingNews--card:hover .LandingNews--card--tag, .LandingNews--card:hover .LandingNews--card--content--text, .LandingNews--card:hover .LandingNews--card--content--link {
  color: white;
}
.LandingNews--card:hover .LandingNews--card--content--link {
  text-decoration: none;
}
.LandingNews--card--tag {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #1095f2;
}
.LandingNews--card--seeall {
  background-color: #1095f2;
}
.LandingNews--card--seeall .LandingNews--card--content {
  justify-content: space-between;
  height: 26.4rem;
  display: flex;
  flex-direction: column;
}
.LandingNews--card--seeall .LandingNews--card--content--text, .LandingNews--card--seeall .LandingNews--card--content--link {
  color: white;
}
.LandingNews--card--seeall .LandingNews--card--content--text {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
}
.LandingNews--card--seeall .LandingNews--card--content--link {
  text-align: right;
}

@media (max-width: 48rem) {
  .LandingNews {
    margin-top: 5rem;
    padding: 0 1.6rem;
    margin-bottom: 3rem;
  }
  .LandingNews--info {
    order: -1 !important;
    height: fit-content;
    padding: 0;
    text-align: center;
  }
}