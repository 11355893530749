main {
  position: relative;
  overflow: hidden;
}

.Home__fly {
  position: fixed;
  bottom: 3rem;
  right: 0;
  z-index: 1;
  background: url(../../../images/fly.svg) no-repeat center center;
  background-size: contain;
  width: 16.6rem;
  height: 13.8rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
}

.popup {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
.popup-content {
  background: url(https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fgrupoletipopup.jpg?alt=media&token=07b267af-1d37-4194-b5fb-fff7c9358f5c) no-repeat center center/contain;
  height: 65rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: #fff;
  position: relative;
}
.popup-content h1 {
  font-family: "TripletaBold";
  font-size: 10rem;
}
.popup-content p {
  width: 530px;
  text-align: center;
}
.popup-zoom {
  position: absolute;
  left: 0;
  bottom: 10rem;
}
.popup-logo {
  margin-top: 5rem;
  width: 24rem;
}
.popup-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(45deg) brightness(104%) contrast(104%);
}

.CookieConsent {
  background: #055aa5 url(../../../images/cookiesBg.svg) no-repeat left center !important;
  background-size: contain;
  padding: 3rem 0;
  position: relative;
  box-shadow: 0 -0.2rem 2rem 0 rgba(0, 0, 0, 0.2);
}
.CookieConsent h1 {
  font-size: 3rem;
}
.CookieConsent button {
  border: solid 0.1rem #fff !important;
  color: #fff !important;
  font-size: 1.6rem !important;
  background: transparent !important;
  transition: all 0.3s ease-in-out;
  padding: 1rem 2rem !important;
  margin-right: 8rem !important;
  margin-top: 3rem !important;
}
.CookieConsent button:hover {
  color: #055aa5 !important;
  background: #fff !important;
}

@media (max-width: 576px) {
  .popup-content h1 {
    font-family: "TripletaBold";
    font-size: 4rem;
  }
  .popup-content p {
    width: 330px;
  }
  .popup-logo {
    margin: 0 0 16rem 0;
    width: 18rem;
  }
  .popup-zoom {
    bottom: 0;
  }
}