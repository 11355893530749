.LandingFAQ {
  padding: 0 6rem;
  padding-top: 4rem;
}
.LandingFAQ--title {
  font-family: "Work Sans", sans-serif;
  font-size: 4rem;
  font-weight: 400;
  color: #808080;
  margin-bottom: 1rem;
  line-height: 1.1;
}
.LandingFAQ--subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #808080;
}
.LandingFAQ a {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  color: #b2b2b2;
  text-transform: uppercase;
  text-decoration: underline;
}
.LandingFAQ a:hover {
  text-decoration: none;
}
.LandingFAQ--btn {
  border-radius: 100px;
  background-color: #1095f2;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 3.6rem;
  padding: 0.8rem 3.2rem;
  display: inline-flex;
  width: fit-content;
}
.LandingFAQ--places {
  display: flex;
  gap: 2rem;
}
.LandingFAQ--place {
  background-color: #f2f2f2;
  border-radius: 100px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
}
.LandingFAQ--accordion .card {
  border: 0;
}
.LandingFAQ--accordion .card:has(.show) .card-header {
  background: url("https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fminus-icon-accordeon.svg?alt=media&token=37a461ab-7fd9-4e64-ad04-8de4b1647343") no-repeat center right 16px/18px;
}
.LandingFAQ--accordion .card-header {
  padding: 0;
  background-color: white;
  border: 0;
  border-bottom: solid 0.2rem #1095f2;
  padding: 12px 0;
  background: url("https://firebasestorage.googleapis.com/v0/b/grupoleti.appspot.com/o/images%2Fplus-icon-accordeon.svg?alt=media&token=a333f355-6c81-4c70-b7fb-b37b92ffd3d8") no-repeat center right 16px/18px;
}
.LandingFAQ--accordion .card-header h5 button {
  font-family: "Work Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #808080;
  text-decoration: none;
  text-align: left;
  padding-right: 6rem;
}
.LandingFAQ--accordion .card-body {
  font-family: "Work Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #808080;
  text-decoration: none;
}
.LandingFAQ--accordion .collapse {
  overflow: hidden;
  height: 0;
  transition: height 0.35s ease-in-out;
}
.LandingFAQ--accordion .collapse.show {
  height: auto;
}

@media (max-width: 48rem) {
  .LandingFAQ {
    padding: 0 1.6rem;
  }
  .LandingFAQ--title {
    text-align: center;
    color: #1095f2;
  }
  .LandingFAQ--accordion .card-header,
  .LandingFAQ--accordion .card:has(.show) .card-header {
    background-position: center right;
  }
  .LandingFAQ--accordion .card-header h5 button,
  .LandingFAQ--accordion .card:has(.show) .card-header h5 button {
    padding: 0;
  }
  .LandingFAQ--subtitle {
    text-align: right;
    margin-top: 2rem;
  }
}