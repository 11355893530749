.List {
  position: relative;
}
.List__to-top {
  height: 8rem;
  width: 8rem;
  background: url(../../../../images/to-top.png) no-repeat center center/contain;
  position: absolute;
  right: -1rem;
  bottom: 3rem;
  z-index: 1;
}
.List__to-top:hover {
  cursor: pointer;
}
.List__sku {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9eaee;
  margin-bottom: 2rem;
  height: 14rem;
}
.List .inactive {
  opacity: 0.3;
}
.List .link.active {
  color: #cd202f;
  text-decoration: underline;
  pointer-events: none;
}
.List .link:hover {
  color: #cd202f;
  text-decoration: underline;
  cursor: pointer;
}
.List__products {
  margin: 6rem auto 12rem auto;
}
.List__products h1 {
  margin-bottom: 6rem;
}
.List__list {
  padding: 0;
  list-style: none;
}
.List__list li {
  list-style: none;
}
.List__card {
  font-size: 1.8rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border: solid 2.4rem #fff;
  border-bottom: solid 0.1rem #e9eaee;
}
.List__card .leti-btn {
  margin-top: 2rem;
}
.List__name {
  color: #055aa5;
}
.List__composition {
  display: block;
  font-size: 1.6rem;
}

@media (max-width: 576px) {
  .List__products h1 {
    margin-bottom: 1rem;
  }
  .List__align {
    justify-content: center;
  }
  .List .link,
  .List .inactive {
    margin: 1rem;
  }
  .List__to-top {
    display: none;
  }
}