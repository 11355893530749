.Science__leti {
  position: relative;
  padding: 0;
  background: #f5f5f5;
  margin-top: 10rem;
}
.Science__leti__image {
  height: 65rem;
}
.Science__leti__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #f5f5f5 url(../../../../images/timeline-bg.svg) no-repeat right center/contain;
  position: relative;
}
.Science__leti__info:before {
  content: "";
  width: 100%;
  height: 22rem;
  top: 0rem;
  background: #fff;
  position: absolute;
  right: 0;
  z-index: 0;
}
.Science__leti__info .leti-btn {
  margin-left: 5rem;
  margin-bottom: 3rem;
}
.Science__leti__desc {
  padding: 2rem 0 2rem 6rem;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 0rem;
}
.Science__leti .slick-slider {
  position: relative;
  display: block;
}
.Science__leti .slick-slider .slick-next,
.Science__leti .slick-slider .slick-prev {
  width: 4rem;
  height: 4rem;
  z-index: 4;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
  position: absolute;
}
.Science__leti .slick-slider .slick-next:hover,
.Science__leti .slick-slider .slick-prev:hover {
  opacity: 1;
}
.Science__leti .slick-slider .slick-next:before,
.Science__leti .slick-slider .slick-prev:before {
  content: "";
}
.Science__leti .slick-slider .slick-next {
  background: url(../../../../images/next-arrow.svg) no-repeat center center/contain;
}
.Science__leti .slick-slider .slick-prev {
  background: url(../../../../images/prev-arrow.svg) no-repeat center center/contain;
}
.Science__title {
  position: absolute;
  top: 13rem;
  left: 6rem;
}

@media (max-width: 1180px) {
  .Science__title {
    font-size: 4.6rem;
  }
}
@media (max-width: 576px) {
  .Science__leti {
    margin-top: 5rem;
  }
  .Science__leti__image {
    height: 38rem;
  }
  .Science__leti__desc {
    padding: 3rem 4rem;
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 6rem;
  }
  .Science__leti .slick-next {
    transform: translate(-25rem, 5rem) !important;
  }
  .Science__leti .slick-prev {
    transform: translate(5rem, 5rem) !important;
  }
  .Science__leti__info .leti-btn {
    margin: 3rem 0;
  }
  .Science__leti__info:before {
    content: unset;
  }
  .Science__leti__btn {
    justify-content: center;
  }
  .Science__title {
    top: 3rem;
    left: 4rem;
    font-size: 3rem;
  }
}