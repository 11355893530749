.FindProduct {
  background: url(../../../../images/find-product-bg.png) no-repeat left center;
  min-height: 42rem;
  display: flex;
  align-items: center;
}
.FindProduct form {
  padding: 0;
}
.FindProduct__row {
  justify-content: center;
}
.FindProduct__form {
  display: flex;
  align-items: center;
  height: 7.6rem;
}
.FindProduct__form .DropdownWithLabel .form-control.category {
  background: url(../../../../images/dropdown-icon.svg) no-repeat 2rem center;
  padding-left: 6rem;
  height: 7.6rem;
  border-radius: 0;
  margin: 0;
  border: none;
  border-top: solid 0.1rem #737b7f;
  border-bottom: solid 0.1rem #737b7f;
}
.FindProduct__form .DropdownWithLabel .form-control.product {
  border-left: solid 0.1rem #737b7f;
  padding-left: 2rem;
  border-left: solid 0.1rem #737b7f;
  background: url(../../../../images/inputwithlabel.svg) no-repeat right center;
}
.FindProduct__form .form-group {
  margin: 0 !important;
}
.FindProduct__form .leti-btn {
  background: url(../../../../images/search.svg) no-repeat center/4rem;
  height: 7.6rem;
  transition: all 0.1s ease-in-out;
}
.FindProduct__form .leti-btn:hover {
  border-width: 0.3rem;
}

@media (max-width: 576px) {
  .FindProduct {
    background: url(../../../../images/find-product-bg.png) no-repeat left 4rem/30%;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 42rem;
  }
  .FindProduct h1 {
    margin: 6rem 0 8rem 11rem !important;
  }
  .FindProduct__form .input-group {
    border: solid 0.1rem #737b7f;
    border-bottom: 0;
  }
  .FindProduct__form .DropdownWithLabel .form-control.category {
    background: #fff url(../../../../images/dropdown-icon.svg) no-repeat 2rem center !important;
    padding-left: 6rem !important;
    border-left: solid 1px #737b7f !important;
  }
  .FindProduct__form .form-group .form-control.category {
    height: 6.2rem;
    border: 0;
  }
  .FindProduct__form .form-group .form-control.category.InputWithLabel {
    background: url(../../../../images/dropdown-resp-bottom.svg) no-repeat center bottom;
    border: 0;
  }
  .FindProduct__form .leti-btn {
    background: none;
    height: 6.2rem;
    width: 100.5%;
  }
  .FindProduct__form .leti-btn .FindProduct {
    background: url(../../../../images/find-product-bg.png) no-repeat left center;
    min-height: 42rem;
    display: flex;
    align-items: center;
  }
  .FindProduct__form .leti-btn .FindProduct form {
    padding: 0;
  }
  .FindProduct__form .leti-btn .FindProduct__row {
    justify-content: center;
  }
  .FindProduct__form .leti-btn .FindProduct__form {
    display: flex;
    align-items: center;
    height: 7.6rem;
  }
  .FindProduct__form .leti-btn .FindProduct__form .DropdownWithLabel .form-control.category {
    background: url(../../../../images/dropdown-icon.svg) no-repeat 2rem center;
    padding-left: 6rem;
    height: 7.6rem;
    border-radius: 0;
    margin: 0;
    border: none;
    border-top: solid 0.1rem #737b7f;
    border-bottom: solid 0.1rem #737b7f;
  }
  .FindProduct__form .leti-btn .FindProduct__form .DropdownWithLabel .form-control.product {
    padding-left: 2rem;
    border-left: solid 0.1rem #737b7f;
    background: url(../../../../images/inputwithlabel.svg) no-repeat right center;
    border-right: none;
    height: 7.6rem;
  }
  .FindProduct__form .leti-btn .FindProduct__form .form-group {
    margin: 0 !important;
  }
  .FindProduct__form .leti-btn .FindProduct__form .leti-btn {
    background: url(../../../../images/search.svg) no-repeat center/4rem;
    height: 7.6rem;
    transition: all 0.1s ease-in-out;
  }
  .FindProduct__form .leti-btn .FindProduct__form .leti-btn:hover {
    border-width: 0.3rem;
  }
}
@media (max-width: 576px) and (max-width: 576px) {
  .FindProduct__form .leti-btn .FindProduct {
    background: url(../../../../images/find-product-bg.png) no-repeat left 4rem/30%;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 42rem !important;
  }
  .FindProduct__form .leti-btn .FindProduct h1 {
    margin: 11rem 0 20rem 11rem;
  }
  .FindProduct__form .leti-btn .FindProduct__form .input-group {
    border: solid 0.1rem #737b7f;
    border-bottom: 0;
  }
  .FindProduct__form .leti-btn .FindProduct__form .DropdownWithLabel .form-control.category {
    background: url(../../../../images/dropdown-icon.svg) no-repeat 2rem center;
    padding-left: 6rem;
  }
  .FindProduct__form .leti-btn .FindProduct__form .form-group .form-control.category {
    height: 6.2rem;
    border: 0;
  }
  .FindProduct__form .leti-btn .FindProduct__form .form-group .form-control.category.InputWithLabel {
    background: url(../../../../images/dropdown-resp-bottom.svg) no-repeat center bottom;
    border: 0;
  }
  .FindProduct__form .leti-btn .FindProduct__form .leti-btn {
    background: none;
    height: 6.2rem;
    width: 100.5%;
  }
  .FindProduct__form .leti-btn .FindProduct__form .leti-btn:before {
    content: "Buscar el producto";
    font-size: 1.8rem;
  }
  .FindProduct__form .leti-btn .FindProduct__form .leti-btn:hover {
    background: blue;
  }
}
@media (max-width: 576px) {
  .FindProduct__form .leti-btn:before {
    content: "Buscar el producto";
    font-size: 1.8rem;
  }
  .FindProduct__form .leti-btn:hover {
    background: blue;
  }
}