.LandingInfo {
  height: 40rem;
  margin-top: -27rem;
}

@media (max-width: 48rem) {
  .LandingInfo {
    height: 30rem;
    margin-top: -12rem;
  }
}