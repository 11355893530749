.BannerProductos {
  margin-top: 20rem;
  position: relative;
}
.BannerProductos__title {
  margin-bottom: 13rem;
  text-align: right;
}
.BannerProductos__subtitle {
  margin-bottom: 30rem;
}
.BannerProductos__img-azitomicina, .BannerProductos__img-diklason, .BannerProductos__img-ulgarin {
  position: absolute;
}
.BannerProductos__img-azitomicina {
  bottom: 0;
  left: 28rem;
  max-width: 40rem;
  height: auto;
  transform: rotate(0deg);
}
.BannerProductos__img-diklason {
  bottom: 10%;
  right: -10rem;
  height: 50rem;
}
.BannerProductos__img-ulgarin {
  transform: rotate(22deg);
  left: 0;
  max-width: 40rem;
  height: auto;
}

@media (max-width: 576px) {
  .BannerProductos {
    margin: 10rem auto 0rem auto;
  }
  .BannerProductos__title {
    margin-bottom: 20rem;
    text-align: right;
  }
  .BannerProductos__subtitle {
    margin-bottom: 30rem;
  }
  .BannerProductos__img-azitomicina {
    width: 80%;
    bottom: 10rem;
    left: -25%;
  }
  .BannerProductos__img-diklason {
    width: 50%;
    height: auto;
    bottom: 4rem;
    right: -2rem;
  }
  .BannerProductos__img-ulgarin {
    top: 20rem;
    left: 4%;
    width: 25rem;
  }
}