.Ethics {
  margin-top: 40rem;
  position: relative;
}
.Ethics strong {
  color: #055aa5;
  font-weight: unset;
}
.Ethics__image {
  margin-top: -4rem;
  width: 530px;
}
.Ethics .leti-btn {
  background: #fff;
}
.Ethics .leti-btn:hover {
  background: #055aa5;
}
.Ethics .big-gray-triangle,
.Ethics .small-gray-triangle {
  position: absolute;
  opacity: 0.4;
  z-index: -1;
}
.Ethics .big-gray-triangle {
  background: url(../../../../images/big-grey.svg) no-repeat center center/contain;
  width: 70rem;
  height: 70rem;
  right: -30rem;
  top: -15rem;
}
.Ethics .small-gray-triangle {
  background: url(../../../../images/small-grey.svg) no-repeat center center/contain;
  width: 50rem;
  height: 55rem;
  right: -3rem;
}

@media (max-width: 1180px) {
  .Ethics {
    margin-top: 10rem;
  }
}
@media (max-width: 576px) {
  .Ethics {
    padding: 2rem;
    font-size: 1.6rem;
  }
  .Ethics .big-gray-triangle,
  .Ethics .small-gray-triangle {
    display: none;
  }
  .Ethics .leti-btn {
    margin: 2rem 0;
  }
}