.NewsPage__hero {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 45rem;
  text-decoration: none;
}
.NewsPage__hero:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(5, 90, 165, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}
.NewsPage__container {
  color: #fff;
  text-shadow: 0 0 3.2rem black;
}
.NewsPage__container span {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
}