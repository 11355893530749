.Footer {
  background: #f5f5f5;
  padding: 8rem 0;
  font-size: 1.8rem;
}
.Footer__logo {
  background: url(../../images/grupo-leti.svg) no-repeat left center/contain;
  height: 7rem;
}
.Footer__info {
  padding-top: 3rem;
}
.Footer__info ul {
  list-style: none;
  padding: 0;
}
.Footer__info ul li {
  font-size: 1.8rem;
  font-weight: 500;
}
.Footer__info a {
  color: #4a4a4a;
  text-decoration: none;
}
.Footer__nav {
  display: flex;
  align-items: center;
}
.Footer__nav li {
  list-style: none;
}
.Footer__nav li a {
  color: #737b7f;
  text-decoration: none;
}
.Footer__nav li a:hover {
  color: #cd202f;
}
.Footer__copy {
  background: #4a4a4a;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  font-size: 1.8rem;
}
.Footer__rrss {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
}
.Footer__icon {
  width: 3rem;
  height: 3rem;
  margin: 0.8rem 0;
}
.Footer__icon.facebook {
  background: url(../../images/facebook.svg) no-repeat center center/cover;
}
.Footer__icon.facebook--blue {
  background: url(../../images/facebook-blue.svg) no-repeat center center/cover;
}
.Footer__icon.whatsapp {
  background: url(../../images/whatsapp.svg) no-repeat center center/cover;
}
.Footer__icon.linkedin {
  background: url(../../images/linkedin.svg) no-repeat center center/cover;
}
.Footer__icon.linkedin--blue {
  background: url(../../images/linkedin-blue.svg) no-repeat center center/cover;
}
.Footer__icon.instagram {
  background: url(../../images/instagram.svg) no-repeat center center/cover;
}
.Footer__icon.instagram--blue {
  background: url(../../images/instagram-blue.svg) no-repeat center center/cover;
}
.Footer__icon.twitter {
  background: url(../../images/twitter.svg) no-repeat center center/cover;
}
.Footer__icon.youtube {
  background: url(../../images/youtube.svg) no-repeat center center/contain;
}
.Footer__icon.youtube--blue {
  background: url(../../images/youtube-blue.svg) no-repeat center center/contain;
}

@media (max-width: 576px) {
  .Footer {
    padding: 4rem 0;
    font-size: 1.6rem;
  }
  .Footer__copy {
    font-size: 1.4rem;
  }
  .Footer__logo {
    margin-left: 2rem;
  }
  .Footer__nav {
    margin-top: 3rem;
    font-size: 1.4rem;
    border-top: solid 0.1rem rgba(74, 74, 74, 0.1);
    padding-top: 3rem;
  }
  .Footer__icon {
    margin-right: 2rem;
    width: 2.5rem;
  }
  .Footer__info ul {
    padding-left: 2rem;
  }
  .Footer__info ul li {
    font-size: 1.6rem;
  }
}