.ProspectPage {
  margin-bottom: 6rem;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}
.ProspectPage__title {
  font-size: 5rem;
  color: #055aa5;
  margin: 3rem 0;
  display: inline-block;
  margin-right: 1rem;
}
.ProspectPage__pic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProspectPage__back {
  color: #055aa5;
  font-size: 2rem;
  display: inherit;
  margin: 8rem auto;
  transition: all 0.3s ease-in-out;
  margin-left: 0;
}
.ProspectPage__back a {
  color: #055aa5;
  text-decoration: none;
}
.ProspectPage__back:hover {
  margin-left: -1rem;
}
.ProspectPage__back:before {
  content: "";
  width: 3.6rem;
  height: 3.6rem;
  background: url(../../../images/back-to-prod.svg) no-repeat center center/contain;
  display: inline;
  margin-right: 1rem;
}
.ProspectPage__product {
  border-bottom: solid 0.1rem rgba(115, 123, 127, 0.4);
  padding-bottom: 6rem;
  margin-bottom: 6rem;
}
.ProspectPage__product .row {
  display: flex !important;
}
.ProspectPage__info {
  font-size: 1.8rem;
  user-select: all;
}
.ProspectPage__info h2 {
  margin-bottom: 3rem;
}
.ProspectPage__info__tag--wrapper {
  background: #f5f5f5;
  border-radius: 5rem;
  border: solid 1px #4a4a4a;
  margin-bottom: 3rem;
  padding: 1.5rem 2rem;
  display: inline-flex;
}
.ProspectPage__info .tag {
  background: #055aa5;
  color: #fff;
  padding: 0.8rem 2rem;
  display: inline-block;
  text-transform: capitalize;
  margin-right: 2rem;
  font-size: 1.6rem;
  border-radius: 5rem;
}
.ProspectPage__info .tag:last-child {
  margin-right: 0;
}
.ProspectPage__info .tag__only {
  margin-right: 0;
}
.ProspectPage__info p {
  color: #737b7f;
}
.ProspectPage__info p strong {
  color: #4a4a4a;
}
.ProspectPage__posology strong {
  font-family: "Poppins", sans-serif;
  font-size: 2.1rem;
  font-weight: 600;
}
.ProspectPage__posology ul {
  padding: 0;
  list-style: none;
}
.ProspectPage__posology ul li {
  font-size: 2rem;
}
.ProspectPage__posology.wborder {
  margin-top: 5rem;
  padding-top: 5rem;
  border-top: solid 0.1rem rgba(115, 123, 127, 0.4);
}
.ProspectPage__another {
  margin-bottom: 10rem;
}
.ProspectPage__another h1 {
  margin: 10rem 0 6rem 0;
}
.ProspectPage__another__principle {
  font-size: 2rem;
  color: #737b7f;
}
.ProspectPage__another__img {
  border-bottom: solid 0.1rem rgba(115, 123, 127, 0.4);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  height: 3rem;
  display: block;
  width: 100%;
}
.ProspectPage__another__img-txt {
  padding-bottom: 1rem;
  display: block;
  height: auto;
  font-size: 3rem !important;
  font-style: italic;
  color: #055aa5;
}
.ProspectPage__another__img-txt sup {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
}
.ProspectPage__another__block {
  display: flex;
  flex-direction: column;
}
.ProspectPage__another__block h2 {
  font-size: 2.4rem;
}
.ProspectPage .slick-next,
.ProspectPage .slick-prev {
  width: 4rem;
  height: 4rem;
  z-index: 4;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
  position: absolute;
  top: 28%;
}
.ProspectPage .slick-next:before,
.ProspectPage .slick-prev:before {
  display: none;
}
.ProspectPage .slick-next:hover,
.ProspectPage .slick-prev:hover {
  opacity: 1;
}
.ProspectPage .slick-next {
  background: url(../../../images/next-arrow.svg) no-repeat center center/contain;
}
.ProspectPage .slick-prev {
  background: url(../../../images/prev-arrow.svg) no-repeat center center/contain;
}
.ProspectPage__cpe {
  opacity: 0.7;
}
.ProspectPage__cb {
  width: 40%;
}

@media (max-width: 576px) {
  .ProspectPage__pic {
    margin-bottom: 0;
    height: 20rem;
  }
  .ProspectPage__product {
    padding-bottom: 0rem;
    margin-bottom: 3rem;
  }
  .ProspectPage__back {
    margin: 2rem auto 2rem auto;
  }
  .ProspectPage__info {
    padding: 2rem;
    font-size: 1.8rem;
  }
  .ProspectPage__info__tag--wrapper {
    padding: 0.8rem 1.2rem;
    display: block;
    background: transparent;
    border: 0;
  }
  .ProspectPage__info .tag {
    margin-bottom: 1rem;
  }
  .ProspectPage__posology {
    padding: 0 2rem;
  }
  .ProspectPage__posology ul li {
    font-size: 1.6rem;
  }
  .ProspectPage__posology strong {
    font-size: 1.6rem;
  }
  .ProspectPage__posology h2 {
    font-size: 1.8rem;
  }
  .ProspectPage__posology.wborder {
    margin-top: 2rem;
    padding-top: 2rem;
  }
  .ProspectPage__another {
    margin-bottom: 0;
  }
  .ProspectPage__another h1 {
    margin: 5rem 0 3rem 0;
    padding: 0 2rem;
  }
  .ProspectPage__another__img {
    padding-bottom: 6rem;
  }
  .ProspectPage__another__img-txt {
    padding-bottom: 2rem;
  }
  .ProspectPage__another__block {
    display: block;
    margin-bottom: 3rem;
    padding: 2rem;
  }
  .ProspectPage .slick-next,
  .ProspectPage .slick-prev {
    top: 3%;
  }
  .ProspectPage .slick-next {
    right: 2rem;
  }
  .ProspectPage .slick-prev {
    left: 2rem;
  }
  .ProspectPage__cb {
    width: 80%;
  }
}