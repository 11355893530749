.LandingTechCard {
  padding: 0 6rem;
}
.LandingTechCard--image {
  height: 50rem;
}
.LandingTechCard--title {
  font-family: "Work Sans", sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: #808080;
  margin-bottom: 2.7rem;
  padding-right: 50%;
}
.LandingTechCard--title strong,
.LandingTechCard--title b {
  font-size: 1.5rem;
  display: block;
}
.LandingTechCard--title strong {
  margin-top: 20px;
}
.LandingTechCard--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.LandingTechCard--content a {
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #1095f2;
  margin-bottom: 2.7rem;
  text-transform: uppercase;
  text-decoration: underline;
}
.LandingTechCard--content a:hover {
  text-decoration: none;
}
.LandingTechCard--btn {
  border-radius: 100px;
  background-color: #1095f2;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 3.6rem;
  padding: 0.8rem 3.2rem;
  display: inline-flex;
  width: fit-content;
}
.LandingTechCard--places {
  display: flex;
  gap: 2rem;
}
.LandingTechCard--place {
  background-color: #f2f2f2;
  border-radius: 100px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 1.6rem;
  min-height: 4rem;
}
.LandingTechCard .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 48rem) {
  .LandingTechCard {
    padding: 0 1.6rem;
  }
  .LandingTechCard--content a {
    text-align: right;
  }
  .LandingTechCard--title {
    padding-right: 0;
  }
  .LandingTechCard--image {
    height: 20rem;
  }
  .LandingTechCard--btn {
    margin-right: auto;
    margin-left: auto;
  }
  .LandingTechCard--places {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }
  .LandingTechCard--place {
    width: 45%;
    margin-bottom: 0 !important;
    min-height: 4rem;
  }
}