body .LandingHeader {
  padding: 0 6rem;
  position: sticky;
  top: 0px;
}
body .LandingHeader--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4rem;
}
body .LandingHeader .left-element,
body .LandingHeader .right-element {
  font-family: "Work Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: #808080;
  text-decoration: none;
}
body .LandingHeader .left-element:not(:last-child) {
  margin-right: 4.5rem;
}
body .LandingHeader .center-element {
  margin: 0 auto; /* Centrar el elemento */
}
body .LandingHeader .right-element {
  margin-left: auto;
}

@media (max-width: 48rem) {
  body .LandingHeader {
    padding: 0 1.6rem;
  }
  body .LandingHeader .left-element,
  body .LandingHeader .right-element {
    display: none;
  }
  body .LandingHeader .center-element {
    margin: 0;
  }
  body .LandingHeader .center-element img {
    width: 26rem;
  }
}