@charset "UTF-8";
body:has(.BiocontrolledInfo-Video) {
  margin-top: 0;
}

.BiocontrolledInfo-Video {
  padding: 0;
  max-width: 100%; /* Para que no exceda el ancho de la pantalla */
  height: 70vh; /* Ajusta el alto que prefieras */
  overflow: hidden; /* Esconde el exceso si el video es más alto */
}
.BiocontrolledInfo-Video video {
  width: 100%; /* Asegura que el video se escale en ancho */
  height: 100%; /* Mantiene el alto del video dentro del contenedor */
  object-fit: cover; /* Asegura que el video llene el contenedor sin distorsión */
}

@media (max-width: 768px) {
  .BiocontrolledInfo-Video {
    height: 50vh; /* Ajusta el alto que prefieras */
  }
}