.DeleteWorkWithUsItem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.DeleteWorkWithUsItem .card {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0;
  border-top: solid 0.1rem #e9eaee;
}
.DeleteWorkWithUsItem .card .leti-btn {
  margin-top: 2rem;
}
.DeleteWorkWithUsItem__ask {
  margin-bottom: 4rem;
}
.DeleteWorkWithUsItem__container {
  background: #fff;
  padding: 4rem;
  font-size: 2rem;
  position: relative;
  overflow: scroll;
  height: auto;
  padding-top: 4rem;
}
.DeleteWorkWithUsItem__close {
  position: absolute;
  top: 3rem;
  right: 2rem;
  font-size: 2rem;
  color: #000;
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  border: solid 0.1rem #055aa5;
  background: url("../../../../images/close.svg") no-repeat center/contain;
  background-size: 50%;
}
.DeleteWorkWithUsItem__close:hover {
  border: solid 0.3rem #055aa5;
}