.EditUnidades__img {
  width: 100%;
  height: 100%;
}
.EditUnidades__trash {
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
}
.EditUnidades__trash:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  background: url(../../../../../images/trash.svg) no-repeat center center/contain;
}
.EditUnidades__trash:hover {
  cursor: pointer;
}