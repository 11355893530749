.VerticalCarousel {
  padding: 0;
}
.VerticalCarousel h1 {
  margin-bottom: 2rem;
}
.VerticalCarousel button {
  cursor: pointer;
}
.VerticalCarousel .outer-container {
  display: flex;
  padding: 14rem 0;
  background: #f5f5f5;
}
.VerticalCarousel .outer-container .carousel-wrapper {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.VerticalCarousel .outer-container .carousel-wrapper p {
  font-weight: 700;
  font-size: 2rem;
  color: #4a4a4a;
  line-height: 1.3rem;
}
.VerticalCarousel .outer-container .content {
  background: #e9eaee;
  font-size: 1.8rem;
}
.VerticalCarousel .outer-container .content-img {
  width: 100%;
  height: 40rem;
  margin-bottom: 2rem;
}
.VerticalCarousel .outer-container .content p {
  padding: 2rem 20rem 2rem 4rem;
}
.VerticalCarousel .outer-container .content p p {
  padding: 0;
}
.VerticalCarousel .carousel-item {
  font-weight: 700;
  font-size: 2rem;
  color: #4a4a4a;
  line-height: 1.3rem;
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 12rem;
  right: 10rem;
  display: block;
  transition: transform 0.4s ease, opacity 0.4s ease;
  text-align: right;
  height: 3.6rem;
}
.VerticalCarousel .carousel-item.visible {
  opacity: 1;
}
.VerticalCarousel .carousel-item.active {
  color: #cd202f;
  font-family: "TripletaBold";
  font-size: 3rem;
}
.VerticalCarousel .leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 0.6rem;
}
.VerticalCarousel .carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: #fff;
}
.VerticalCarousel .carousel-button {
  display: flex;
  align-self: center;
  padding: 1rem;
  max-width: 5rem;
  border: 0;
  background: transparent;
  margin-left: 30rem;
}
.VerticalCarousel .carousel-button svg {
  height: 3rem;
  width: 3rem;
}
.VerticalCarousel .carousel-button path {
  fill: rgba(74, 74, 74, 0.4);
}
.VerticalCarousel .carousel-button.prev {
  transform: rotate(90deg);
}
.VerticalCarousel .carousel-button.next {
  transform: rotate(90deg);
}
.VerticalCarousel .slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}
.VerticalCarousel .carousel-inner {
  position: relative;
  max-height: 25rem;
  height: 25rem;
  margin-top: calc(50% - 25rem);
}
.VerticalCarousel .carousel-inner:before, .VerticalCarousel .carousel-inner:after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 11rem;
  pointer-events: none;
}
.VerticalCarousel .carousel-inner:before {
  top: 0;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0%, hsl(0, 0%, 96%) 100%);
}
.VerticalCarousel .carousel-inner:after {
  bottom: 0;
  background: linear-gradient(0deg, hsl(0, 0%, 96%) 0%, hsla(0, 0%, 100%, 0) 100%);
}

@media (max-width: 576px) {
  .VerticalCarousel {
    margin-bottom: 6rem;
  }
  .VerticalCarousel h1 {
    text-align: center;
  }
  .VerticalCarousel h1.w-margin {
    margin-top: 4rem;
    padding: 2rem 4rem;
  }
  .VerticalCarousel__card {
    width: 100%;
  }
  .VerticalCarousel__card-img {
    width: auto;
    height: 36rem;
  }
  .VerticalCarousel__card-body {
    background: #f5f5f5;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 2rem;
    color: #4a4a4a;
  }
  .VerticalCarousel__card-body h3 {
    font-family: "TripletaBold";
    font-size: 2.4rem;
    color: #cd202f;
    text-align: center;
    margin: 2rem auto;
  }
  .VerticalCarousel .slick-dots li.slick-active button:before {
    border: solid 0.1rem #737b7f;
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #737b7f;
  }
}